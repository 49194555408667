import { getNetworkTypeText } from '../../../../components/CoverageMapCard/network-type-text'
import { useGeneralStore } from '../../../../store'
import { Address } from '../../../../store/address'
import { Logger } from '../../../../utils/Logger'
import { ChatFlowSelector } from '../../ChatFlow'
import { ValidatedResults } from '../../ServerResponse'
import { BotTextMessage, ChatInteraction, ChatStep, MessageType } from '../../types/ChatStep'
import { FreeBotMessage } from '../../types/free-chat.types'
import { Suggestion, SuggestionType } from '../../types/Suggestion'
import { ChatInterface, ToolHandler } from '../const/tool.const'

// TODO: this messages are not fully approved by client
export function getMapPrependedMessages(input: CoverageMapCardBackendInput): {
  messages: BotTextMessage[]
  updatedInput: CoverageMapCardBackendInput
} {
  let messages: BotTextMessage[] = []
  let updatedInput: CoverageMapCardBackendInput = { ...input }
  const networkType = getNetworkTypeText(input.networkType)

  if (input.carrierStatus === 'unknown' || input.carrierStatus === 'failed') {
    messages.push({
      type: MessageType.TEXT,
      from: 'bot',
      text: `Looks like we couldn't recognize that carrier. No problem! Here's the best ${networkType} carrier available for your location.`
    })
    updatedInput.wantsBestCarrier = true
  } else if (input.networkType === 'invalid') {
    messages.push({
      type: MessageType.TEXT,
      from: 'bot',
      text: `Hmm, we didn't recognize that mobile generation. No worries—check out the ${networkType} coverage details we found for you!`
    })
  } else if (input.wantsBestCarrier) {
    messages.push({
      type: MessageType.TEXT,
      from: 'bot',
      text: `Based on this location. The carrier below provides the best ${networkType} performance.`
    })
  } else {
    messages.push({
      type: MessageType.TEXT,
      from: 'bot',
      text: `Got it! Here's the ${networkType} coverage for this location.`
    })
  }
  return { messages, updatedInput }
}

export type CoverageMapCardBackendInput = {
  action: 'showSuggestions' | 'menuSelection' | 'showMap' | null
  address: string | null
  isCurrentLocation: boolean
  isMissingLocation: boolean
  knownLocation: string | null
  networkType: '4g' | '5g' | 'invalid'
  carrier: string | null
  carrierId: string | null
  carrierStatus: 'valid' | 'failed' | 'unknown' | null
  wantsBestCarrier: boolean
  // Front-end custom params
  placeId?: string
}

export class CoverageMapSuggestionHandler {
  private coverageMapToolHandler: CoverageMapToolHandler
  private toolCall: ChatInterface<CoverageMapCardBackendInput>['toolCall']

  constructor(address: Address, chatInterface: Omit<ChatInterface<any>, 'toolCall'>) {
    this.toolCall = {
      name: 'CoverageMap',
      input: {
        action: 'showMap',
        address: address.addressName,
        placeId: address.placeId,
        knownLocation: address.label ?? null,
        isCurrentLocation: false,
        isMissingLocation: false,
        carrier: null,
        carrierId: null,
        carrierStatus: null,
        networkType: '5g',
        wantsBestCarrier: false
      }
    }
    this.coverageMapToolHandler = new CoverageMapToolHandler({ ...chatInterface, toolCall: this.toolCall })
  }
  public async addToolMessages() {
    this.coverageMapToolHandler.onMessage({
      type: MessageType.TOOL,
      from: 'bot',
      tool: 'CoverageMap',
      toolComponent: 'CoverageMapCardToolComponent',
      backendInput: this.toolCall.input
    })
  }
}

export function getCoverageMapToolDefaultSuggestionsMessage(): ChatStep {
  return {
    from: 'bot',
    type: MessageType.STEP,
    question: [{ text: 'Is there anything else I can help with?' }],
    interactions: [ChatInteraction.FREE_TEXT],
    suggestions: [
      {
        type: SuggestionType.TEXT,
        text: 'Run a speed test',
        value: { parsedAnswer: 'Run a speed test', resultStatus: ValidatedResults.VALID }
      },
      {
        type: SuggestionType.TEXT,
        text: 'Find me the perfect plan',
        value: { parsedAnswer: 'Find me the perfect plan', resultStatus: ValidatedResults.VALID }
      }
    ]
  }
}

export function getAddressesSuggestions(addresses: Address[]): Suggestion[] {
  return [
    {
      type: SuggestionType.CURRENT_LOCATION,
      text: 'Current Location',
      value: { parsedAnswer: 'Current Location', resultStatus: ValidatedResults.VALID }
    },
    ...addresses.map((address) => ({
      type: SuggestionType.LOCATION,
      location: address,
      text: address.label,
      value: { parsedAnswer: address.label, resultStatus: ValidatedResults.VALID }
    }))
  ]
}

export class CoverageMapToolHandler implements ToolHandler<CoverageMapCardBackendInput> {
  constructor(private chatInterface: ChatInterface<CoverageMapCardBackendInput>) {}

  onMessage(message: FreeBotMessage) {
    this.chatInterface.onMessage({
      messageRecord: { message }
    })
  }

  private showMap() {
    const backendInput: CoverageMapCardBackendInput = {
      ...this.chatInterface.toolCall.input
    }
    this.onMessage({
      from: 'bot',
      type: MessageType.TOOL,
      backendInput,
      tool: this.chatInterface.toolCall.name,
      toolComponent: 'CoverageMapCardToolComponent'
    })
  }

  async handle() {
    if (this.chatInterface.toolCall.input.action === 'showSuggestions') {
      this.onMessage({
        type: MessageType.STEP,
        question: [{ text: 'What can I help you with?' }],
        interactions: [ChatInteraction.FREE_TEXT],
        suggestions: ChatFlowSelector.question__start_plan_finder.suggestions,
        from: 'bot'
      })
      return
    }

    if (
      this.chatInterface.toolCall.input.action === 'menuSelection' ||
      this.chatInterface.toolCall.input.action === null
    ) {
      this.onMessage(ChatFlowSelector.coverage__start)
      return
    }

    const addresses = useGeneralStore.getState().addresses

    if (this.chatInterface.toolCall.input.isMissingLocation) {
      this.onMessage({
        from: 'bot',
        question: [{ text: 'Please provide an address to check the coverage.' }],
        type: MessageType.STEP,
        interactions: [ChatInteraction.ADDRESS],
        suggestions: getAddressesSuggestions(addresses)
      })
      return
    }

    if (this.chatInterface.toolCall.input.isCurrentLocation) {
      this.onMessage({
        from: 'bot',
        question: [{ text: 'Sure! Which location would you like to check?' }],
        subtitle: "Type a location or select a label you've saved.",
        type: MessageType.STEP,
        interactions: [ChatInteraction.ADDRESS],
        suggestions: [
          {
            type: SuggestionType.CURRENT_LOCATION,
            text: 'Current Location',
            value: { parsedAnswer: 'Current Location', resultStatus: ValidatedResults.VALID }
          },
          ...addresses.map((address) => ({
            type: SuggestionType.LOCATION,
            location: address,
            text: address.label,
            value: { parsedAnswer: address.label, resultStatus: ValidatedResults.VALID }
          }))
        ]
      })
      return
    }

    // CASE: Known location
    if (this.chatInterface.toolCall.input.knownLocation) {
      Logger.warn('knownLocation: WIP')
      if (!addresses.find((address) => address.label === this.chatInterface.toolCall.input.knownLocation)) {
        this.onMessage({
          from: 'bot',
          type: MessageType.STEP,
          question: [{ text: "Sure, but I don't know where that is. Can you provide an address?" }],
          interactions: [ChatInteraction.ADDRESS],
          suggestions: [
            {
              type: SuggestionType.CURRENT_LOCATION,
              text: 'Current Location',
              value: { parsedAnswer: 'Current Location' }
            },
            ...addresses.map((address) => ({
              type: SuggestionType.LOCATION,
              location: address,
              text: address.label,
              value: { parsedAnswer: address.label }
            }))
          ]
        })
        return
      }
    }

    this.showMap()
  }
}

import { cx } from '@emotion/css'
import { IonButton, IonPage } from '@ionic/react'
import React from 'react'
import { useQueryClient } from 'react-query'
import { ChatbotContentContainerStyles } from '../ChatbotPage.styles'
import { ChatComponent } from '../components/ChatComponent'
import { useServerChat } from '../hooks/useServerChat'
import { FreeChatPageStyles } from './FreeChatPageStyles'
import { FreeChat, getDefaultTimeline } from '../utils/chatApiSource'
import { OnMessagePayload } from '../hooks/useChat'
import { RoutePaths } from '../../../paths'
import { freeChatQueryKey, useFreeChat, useSendAnswerToLLM } from './hooks/useFreeChat'

type FreeChatErrorBoundaryProps = { resetChat: () => void; children: React.ReactNode }
class FreeChatErrorBoundary extends React.Component<FreeChatErrorBoundaryProps> {
  state: { hasError: false }

  constructor(props: FreeChatErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  resetChat() {
    this.props.resetChat()
    requestAnimationFrame(() => {
      this.setState({ hasError: false })
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <section style={{ color: '#000' }}>
          <h1>Something went wrong.</h1>
          <div>Your current chat state might be broken</div>
          <IonButton routerLink={RoutePaths.root}>Go to the home page</IonButton>
          <IonButton onClick={() => this.resetChat()}>Reset chat state</IonButton>
        </section>
      )
    }
    return this.props.children
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true }
  }
}

export type FreeChatPageProps = {
  autocompleteService?: google.maps.places.AutocompleteService
}

export function FreeChatPage(props: FreeChatPageProps) {
  const freeChat = useFreeChat()
  const sendAnswerToLLMMutation = useSendAnswerToLLM()

  const queryClient = useQueryClient()

  const sendAnswerToLLM = React.useCallback(
    async (payload: { userAnswer: string }) => {
      const timeline = queryClient.getQueryData<FreeChat>(freeChatQueryKey)?.chatTimeline || []
      const result = await sendAnswerToLLMMutation.mutateAsync({ userAnswer: payload.userAnswer, timeline })
      return result
    },
    [sendAnswerToLLMMutation, queryClient]
  )

  const mutateFreeChat = freeChat.mutate
  const onMessage = React.useCallback(
    async (payload: OnMessagePayload) => {
      mutateFreeChat({
        action: 'push',
        state: [payload.messageRecord.message]
      })
    },
    [mutateFreeChat]
  )

  const chat = useServerChat({
    onMessage,
    sendAnswerToLLM,
    timeline: freeChat.query.data?.chatTimeline || []
  })

  const restart = React.useCallback(() => {
    mutateFreeChat({ action: 'replace', state: getDefaultTimeline() })
  }, [mutateFreeChat])

  return (
    <IonPage className={cx(FreeChatPageStyles, ChatbotContentContainerStyles)}>
      <FreeChatErrorBoundary resetChat={restart}>
        <ChatComponent
          autocompleteService={props.autocompleteService}
          title='Chat with Barry'
          onRestart={restart}
          isLoading={sendAnswerToLLMMutation.isLoading || !freeChat.query.data}
          chatStatus={'active'}
          chat={chat}
        />
      </FreeChatErrorBoundary>
    </IonPage>
  )
}

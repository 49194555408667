import { IonButton, IonSpinner } from '@ionic/react'
import { ChatInputStyles } from './ChatInput.styles'
import { GojiInputStyles } from '../../../GojiInput.styles'
import React from 'react'

export interface BaseChatInputProps {
  value: string
  setValue: (value: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  disabled?: boolean
  state: 'sending' | 'idle'
  onSend: React.MouseEventHandler<HTMLIonButtonElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

export const preventDefault = (e: React.MouseEvent) => {
  e.preventDefault()
}

function BaseChatInput(props: BaseChatInputProps, ref: React.Ref<{ focus: () => void }>) {
  const textInput = React.useRef<HTMLInputElement>(null)

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      textInput.current?.focus()
    },
    getValue: () => textInput.current?.value ?? ''
  }))

  return (
    <div className={ChatInputStyles}>
      <input
        value={props.value}
        placeholder={props.placeholder}
        onInput={(e) => props.setValue(e.currentTarget.value)}
        tabIndex={1}
        className={GojiInputStyles}
        onKeyDown={props.onKeyDown}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        title='Chat Input'
        ref={textInput}
        type='text'
      />
      <IonButton
        role='button'
        disabled={props.disabled || props.state === 'sending' || props.value.trim() === ''}
        shape='round'
        className='send-button'
        onClick={props.onSend}
        onMouseDown={preventDefault}
        title='Send'
        aria-label='Send'
        data-testid='send-button'
      >
        {props.state === 'sending' ? (
          <IonSpinner name='dots' className='spinner' />
        ) : (
          <img className='send-image' src='/assets/images/chat/send.svg' alt='send' />
        )}
      </IonButton>
    </div>
  )
}

export default React.forwardRef(BaseChatInput)

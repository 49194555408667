import { useFlags } from 'launchdarkly-react-client-sdk'
import { FeatureFlag } from '../FeatureFlags'

export function useFlag<T extends keyof typeof FeatureFlag>(flagKey?: (typeof FeatureFlag)[T]) {
  const flags = useFlags()

  if (process.env.NODE_ENV === 'test') {
    return true
  }

  return flagKey ? flags[flagKey] : true
}

import { css } from '@emotion/css'
import { IonButton, IonContent, IonPage } from '@ionic/react'
import ChatHeaderComponent from './components/ChatHeaderComponent'
import { EllipseBackground } from '../../components/EllipseBackground/EllipseBackground'
import { ChatFooter } from './components/PlanSwipe/ChatInput/ChatInput'
import { RoutePaths } from '../../paths'
import { useAutoChatStorage } from './hooks/useChatStorage'
import { IChatFlow } from './types/ChatStep'
import { MainNavigationPaths } from '../../paths'
import { MOBILE_BREAKPOINT, useIsMobileSize } from '../../utils/hooks/useWindowSize'
import { EllipseBackgroundDesktop } from '../../components/EllipseBackground/EllipseBackgroundDesktop'
import { useLocation } from 'react-router'
import { trackAmplitudeEvent } from '../../utils/amplitude'

const TransitionStyle = css`
  height: 100%;
  .transition-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    color: var(--ion-color-dark);
    padding: 64px 24px;
    @media (min-width: ${MOBILE_BREAKPOINT}px) {
      padding: 128px 40px;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: 900;
    text-align: center;
    @media (min-width: ${MOBILE_BREAKPOINT}px) {
      max-width: 600px;
    }
  }
`

function onFinishSwiping() {
  trackAmplitudeEvent('ChatBarry - End Plan Finding')
}

const PlanSwipeTransitionPage = () => {
  const userPreferences = useAutoChatStorage().userPreferences
  const isMobile = useIsMobileSize()
  const location = useLocation()
  const { restartChat } = useAutoChatStorage<IChatFlow>()

  return (
    <IonPage>
      <ChatHeaderComponent
        title='Plan Finder'
        resetLink={RoutePaths.chatFinder + location.search}
        onResetClick={restartChat}
      />
      <IonContent className={TransitionStyle}>
        {isMobile ? <EllipseBackground /> : <EllipseBackgroundDesktop />}
        <div className='transition-content'>
          <img src='/assets/images/barry/barry_star_background.svg' alt='barry' />
          <h2>Thanks! And now (drum roll please) here are the plans we recommend...</h2>
        </div>
      </IonContent>
      <ChatFooter>
        <IonButton
          routerLink={MainNavigationPaths.recommendedPlans + '?numLines=' + userPreferences?.numLines}
          size='large'
          color='primary'
          className='finish-button'
          shape='round'
          expand='block'
          onClick={onFinishSwiping}
        >
          LET’S GO!
        </IonButton>
      </ChatFooter>
    </IonPage>
  )
}

export default PlanSwipeTransitionPage

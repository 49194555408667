import { MainNavigationPaths, RoutePaths } from '../../../../paths'
import { Logger } from '../../../../utils/Logger'
import { ChatFlowSelector } from '../../ChatFlow'
import { FreeBotMessage } from '../../types/free-chat.types'
import { ChatInterface, TOOL_NAME, ToolHandler } from '../const/tool.const'
import { ChatInteraction, MessageType } from '../../types/ChatStep'
import { NetworkMeterService } from '../../../../services/NetworkMeter.service'
import { Suggestion, SuggestionType } from '../../types/Suggestion'
import useAuthStore from '../../../../store/auth'
import { arrowForward } from 'ionicons/icons'
import { ValidatedResults } from '../../ServerResponse'
import { getLastTool } from '../../hooks/getLastQuestion'
import { MessageRedirection } from './utils/MessageRedirection'

export type RunTestBackendInput = {
  action: 'runTest'
}

export type WatchHistoryBackendInput = {
  action: 'watchHistory'
}

export type ShowMeterSuggestionsBackendInput = {
  action: 'showSuggestions'
  params: {
    wasNegativeAnswer: boolean
  }
}

export type MeterMenuSelectionInput = {
  action: 'menuSelection'
}

export type MeterToolBackendInput =
  | RunTestBackendInput
  | WatchHistoryBackendInput
  | ShowMeterSuggestionsBackendInput
  | MeterMenuSelectionInput
export class MeterToolHandler implements ToolHandler<MeterToolBackendInput> {
  private messageRedirection: MessageRedirection<MeterToolBackendInput>
  constructor(private chatInterface: ChatInterface<MeterToolBackendInput>) {
    this.messageRedirection = new MessageRedirection(chatInterface)
  }

  onMessage(message: FreeBotMessage) {
    return this.chatInterface.onMessage({
      messageRecord: {
        message
      }
    })
  }

  private showMenu() {
    this.onMessage(ChatFlowSelector.meter__start)
  }

  private runTest() {
    this.messageRedirection.messageRedirection(MainNavigationPaths.meter, {
      action: 'static',
      params: {
        descriptor: {
          redirectingText: 'Got it! Taking you to the network meter...',
          question: {
            from: 'bot',
            type: MessageType.STEP,
            question: [
              {
                text: 'Welcome back! What can I help you with?'
              }
            ],
            suggestions: [],
            interactions: [ChatInteraction.FREE_TEXT]
          }
        }
      }
    })
  }

  private showSuggestions(wasNegativeAnswer: boolean) {
    const timeline = this.chatInterface.getTimeline()

    const lastTool = getLastTool(timeline)
    const suggestions: Suggestion[] = []

    if (
      lastTool &&
      lastTool.type === MessageType.TOOL &&
      lastTool.tool === 'NetworkMeter' &&
      (lastTool.backendInput as any).action === 'watchHistory' &&
      !wasNegativeAnswer
    ) {
      suggestions.push({
        type: SuggestionType.TEXT,
        text: 'Run a speed test',
        value: {
          parsedAnswer: 'Run a speed test'
        }
      })
    }

    this.onMessage({
      from: 'bot',
      type: MessageType.STEP,
      question: [
        {
          text: 'Okay! Is there anything else I can help you with?'
        }
      ],
      suggestions: [
        ...suggestions,
        {
          type: SuggestionType.TEXT,
          text: 'What does my coverage look like?',
          value: { parsedAnswer: 'What does my coverage look like?', resultStatus: ValidatedResults.VALID }
        },
        {
          type: SuggestionType.TEXT,
          text: 'Do you have any interesting plans?',
          value: { parsedAnswer: 'Do you have any interesting plans?', resultStatus: ValidatedResults.VALID }
        }
      ],
      interactions: [ChatInteraction.FREE_TEXT]
    })
  }

  private async watchHistory() {
    const currentUser = useAuthStore.getState().currentUser

    if (!currentUser) {
      this.onMessage({
        from: 'bot',
        type: MessageType.STEP,
        question: [
          {
            text: 'Sign in below to see your most recent speed tests.'
          }
        ],
        suggestions: [
          {
            type: SuggestionType.LINK_WITH_ICON,
            text: 'Sign in',
            routerLink: MainNavigationPaths.signIn + '?open-from=' + RoutePaths.chatFree,
            icon: arrowForward,
            mode: 'button',
            value: {
              parsedAnswer: 'Sign in'
            }
          },
          {
            type: SuggestionType.TEXT,
            text: "I'm already signed in",
            value: {
              parsedAnswer: "I'm already signed in"
            }
          }
        ],
        interactions: [ChatInteraction.FREE_TEXT]
      })
      return
    }

    let networkMeterHistory = await NetworkMeterService.getNetworkMeterRecords()
    if (networkMeterHistory.length === 0) {
      await this.onMessage({
        from: 'bot',
        type: MessageType.STEP,
        question: [
          {
            text: "It looks like you haven't run any speed tests yet to show your recent results! Would you like to run a speed test now?"
          }
        ],
        suggestions: [
          {
            type: SuggestionType.TEXT,
            text: 'Yes',
            value: {
              parsedAnswer: 'Yes'
            }
          }
        ],
        interactions: [ChatInteraction.FREE_TEXT]
      })
      return
    }

    this.onMessage({
      from: 'bot',
      type: MessageType.TEXT,
      text: 'Here are your three most recent speed tests:'
    })

    this.onMessage({
      from: 'bot',
      type: MessageType.TOOL,
      backendInput: this.chatInterface.toolCall.input,
      tool: TOOL_NAME.NETWORK_METER,
      toolComponent: 'NetworkMeterHistory'
    })

    this.onMessage({
      from: 'bot',
      type: MessageType.TEXT,
      text: 'Would you like to run a speed test now?'
    })
  }

  handle(chatInterface: ChatInterface<MeterToolBackendInput>): void {
    const { input } = chatInterface.toolCall
    if (input.action === null) {
      this.showMenu()
      return
    }

    if (input.action === 'menuSelection') {
      this.onMessage(ChatFlowSelector.meter__start)
      return
    }

    if (input.action === 'runTest') {
      this.runTest()
      return
    }

    if (input.action === 'watchHistory') {
      this.watchHistory()
      return
    }

    if (input.action === 'showSuggestions') {
      this.showSuggestions(input.params.wasNegativeAnswer)
      return
    }

    Logger.error('Meter tool call not handled:', chatInterface.toolCall)
  }
}

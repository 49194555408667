import { css } from '@emotion/css'

export const WifiPopupStyles = css`
  h1 {
    margin-top: 0;
  }
  .description {
    margin-bottom: 40px;
  }

  .close-wrapper {
    display: flex;
    justify-content: end;
  }

  ion-button {
    text-transform: uppercase;
    font-weight: 700;
    height: 52px;
    &::part(native) {
      font-size: 16px;
      letter-spacing: 0.3px;
    }
  }
`

export const MobileBrowserNetworkMeterStyles = css`
  h2,
  h1,
  h4 {
    margin: 0;
  }

  h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  h4 {
    font-weight: 400;
  }

  .lower-measurement .measurement-value {
    font-size: 20px;
  }

  .upper-row,
  .lower-row {
    display: grid;
    gap: 8px;
  }
  .upper-row {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .download-icon {
    display: inline-block;
    margin-top: 16px;
  }
  .lower-row {
    padding-top: 8px;
    grid-template-columns: 1fr 1fr;
  }

  .speed-measurement {
    white-space: nowrap;
  }
  .network-meter {
    position: relative;
    margin-top: 30px;
  }

  .network-meter-value {
    width: 100%;
  }

  .network-meter-value,
  .indicators {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--ion-color-medium);
  }

  .indicators > * {
    position: absolute;
  }
  .mbps-description {
    font-size: 14px;
    font-weight: 500;
    color: var(--ion-color-medium);
  }

  .main-heading {
    margin-top: 12px;
    margin-bottom: 24px;
  }
  .wifi-description {
    padding-top: 12px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: var(--ion-color-medium);
  }
  .measurements {
    margin-top: 20px;
  }
  .button-md {
    height: 52px;
    text-transform: uppercase;
    &::part(native) {
      letter-spacing: 0.3px;
      font-size: 16px;
    }
  }
  .network-type {
    text-align: center;
    margin: 24px 0;
    color: var(--ion-color-medium);
    span {
      color: var(--goji-primary-black);
      font-size: 18px;
      line-height: 27px;
    }
  }
`

export const NetworkMeterHistoryStyles = css`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .history-title {
    font-size: 20px;
    font-weight: 900;
  }

  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
`

export const MeterHistoryTableStyles = css`
  overflow: auto;
  display: flex;
  .table {
    --ion-grid-columns: 18;
    display: flex;
    flex-direction: column;
    color: var(--ion-color-dark);

    > * {
      border-bottom: 1px solid var(--ion-color-light);
    }
    > *:last-child {
      border-bottom: none;
    }

    font-size: 16px;

    .row {
      padding: 6px 4px !important;
      &.header {
        padding: 12px 0;
        background-color: var(--ion-color-light);
        border-radius: 10px 10px 0 0;
        font-size: 14px;

        ion-icon {
          width: 17px;
          height: 17px;
        }
      }
      &:not(.header) {
        cursor: pointer;
        :hover {
          background-color: var(--goji-color-secondary-blue);
        }
      }
    }
    .column {
      padding: 8px !important;
      display: flex;
      align-items: center;
      padding: 8px 0;
      text-align: left;

      &.address {
        display: inline-block;
        align-self: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &.date {
        font-size: 12px;
      }
      &.speed-metric,
      &.speed-metric-header {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
      &.arrow {
        justify-content: center;
      }

      .metric-icon {
        color: var(--ion-color-medium);
        width: 20px;
        height: 20px;
      }
      .type-icon {
        color: var(--ion-color-dark);
        width: 20px;
        height: 20px;
      }
      .arrow-icon {
        color: var(--ion-color-medium);
        width: 16px;
        height: 16px;
      }
    }
  }
`

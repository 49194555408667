import React from 'react'
import { BotToolMessage } from '../types/ChatStep'
import { Suggestion } from '../types/Suggestion'
import { ToolComponents, ToolName } from './const/tool.const'

export type ToolError = {
  error: unknown
}
export interface Tool<T extends React.ComponentType<any>> {
  toolName: ToolName
  ToolComponent: T
  toProps: () => Promise<React.ComponentProps<T> | ToolError>
}

export function ToolComponent(props: {
  message: BotToolMessage
  isLast: boolean
  onSuggestionSelected: (suggestion: Suggestion) => void
}) {
  const Comp = ToolComponents[props.message.toolComponent] as any

  if (!Comp) {
    return null
  }

  return (
    <Comp
      isLast={props.isLast}
      onSuggestionSelected={props.onSuggestionSelected}
      backendInput={props.message.backendInput as any}
    />
  )
}

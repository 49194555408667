import React from 'react'

export function useScrollToBottom(
  scrollArea: React.RefObject<HTMLIonContentElement>,
  list: unknown[],
  isLoading = false
) {
  React.useEffect(() => {
    if (!scrollArea.current) {
      return
    }

    setTimeout(
      () => {
        if (!scrollArea.current) {
          return
        }
        scrollArea.current.scrollToBottom(200)
      },
      process.env.NODE_ENV === 'test' ? 0 : 100
    )
  }, [list, scrollArea, isLoading])
}

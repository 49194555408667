import { css, cx } from '@emotion/css'
import { IonRouterLink } from '@ionic/react'
import { RoutePaths } from '../../paths'
import { MOBILE_BREAKPOINT } from '../../utils/hooks/useWindowSize'

const chatEntryButtonStyles = css`
  background-color: white;
  border-radius: 42px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  padding: 16px 24px;
  margin-bottom: 26px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 690px;
  }

  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 22px;
`

interface ChatEntryButtonProps {
  className?: string
}

const ChatEntryButton = ({ className }: ChatEntryButtonProps) => {
  return (
    <IonRouterLink routerLink={RoutePaths.chatFree}>
      <div className={cx(chatEntryButtonStyles, className)}>Ex. What's my coverage at work?</div>
    </IonRouterLink>
  )
}

export default ChatEntryButton

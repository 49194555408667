import { useQuery } from 'react-query'
import API from '../api'
import { queryClient } from '../queryClient'

export interface NetworkMeterRecordBody {
  latitude: number | null
  longitude: number | null
  placeId: string
  address: string
  connectionType: string
  downloadSpeedMbps: number
  uploadSpeedMbps: number
  latencyMs: number
  jitterMs: number
  uploadedLatencyMs: number
  uploadedJitterMs: number
  downloadedLatencyMs: number
  downloadedJitterMs: number
  packetLossPercentage: number
  streamingScorePoints: number
  gamingScorePoints: number
  rtcScorePoints: number
  streamingScore: string
  gamingScore: string
  rtcScore: string
  carrier: string
  device: string
  deviceInfo: {
    model: string
    platform: string
    isVirtual: boolean
    osVersion: string
    manufacturer: string
    operatingSystem: string
  }
  server: string
}

export interface NetworkMeterRecord extends NetworkMeterRecordBody {
  id: string
  capturedOn: string
  ipAddress: string
}

export const NetworkMeterService = {
  getNetworkMeterRecords: async (): Promise<NetworkMeterRecord[]> => {
    return await API.get('/meter-history')
  },
  addNetworkMeterRecord: async (record: NetworkMeterRecordBody) => {
    const response = await API.post('/meter-history', { ...record })
    queryClient.invalidateQueries('network-meter-records')
    return response
  },
  deleteNetworkMeterRecord: async (id: string): Promise<any> => {
    const response = await API.delete(`/meter-history/${id}`)
    queryClient.invalidateQueries('network-meter-records')
    return response
  }
}

export const useNetworkMeterRecords = {
  useNetworkMeterRecords: () => {
    return useQuery({
      queryKey: 'network-meter-records',
      async queryFn() {
        return await NetworkMeterService.getNetworkMeterRecords()
      }
    })
  }
}

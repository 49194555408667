import { useQuery } from 'react-query'
import useAddressPredictions from '../../utils/hooks/usePredictions'
import { getAddressFromPlaceId, predictionToAddress } from '../../utils/Address'
import CoverageMapCard from './CoverageMapCard'
import {
  CoverageMapCardBackendInput,
  getCoverageMapToolDefaultSuggestionsMessage,
  getMapPrependedMessages
} from '../../pages/llm/tools/handlers/CoverageMapToolHandler'
import RegionCoverageMapCard from './RegionCoverageMapCard'
import { isGeocoderType } from '../Map/useHighlightRegion'
import { useBoundStore } from '../../store'
import CoverageCard from './CoverageCard'
import { AddressNotFoundMapMessage, ErrorMapCard, LoadingMapCard } from './CoverageMapCardStates'
import { Suggestion } from '../../pages/llm/types/Suggestion'
import { Address } from '../../store/address'
import Message from '../../pages/llm/components/Message'
import ChatStepComponent from '../../pages/llm/components/ChatQuestionComponent'
import { getNetworkTypeText } from './network-type-text'

export type CoverageMapCardToolProps = {
  isLast: boolean
  backendInput: CoverageMapCardBackendInput
  onSuggestionSelected: (suggestion: Suggestion) => void
}

export function CoverageMapCardToolComponent(props: CoverageMapCardToolProps) {
  const { predictions } = useAddressPredictions({
    value: props.backendInput.placeId ? '' : props.backendInput.address ?? '',
    types: []
  })
  const addresses = useBoundStore((state) => state.addresses)
  const addressQuery = useQuery({
    enabled: !!predictions.isFetched || !!props.backendInput.placeId || !!props.backendInput.knownLocation,
    queryKey: ['address-string', predictions, props.backendInput],
    keepPreviousData: true,
    queryFn: async () => {
      if (props.backendInput.knownLocation) {
        const knownAddress = addresses.find((address) => address.label === props.backendInput.knownLocation)
        if (knownAddress) {
          return { address: knownAddress, types: [] }
        }
      }
      if (props.backendInput.placeId) {
        const [address, geolocation] = await Promise.all([
          getAddressFromPlaceId(props.backendInput.placeId),
          new google.maps.Geocoder().geocode({ placeId: props.backendInput.placeId })
        ])
        if (!address) {
          return undefined
        }
        return { address: address, types: geolocation.results[0].types }
      }
      if (!predictions.data?.length) {
        return undefined
      }
      const address = { ...(await predictionToAddress(predictions.data[0])), localMemory: true }
      return { address: address, types: predictions.data[0].types }
    }
  })

  if (addressQuery.isLoading || predictions.isLoading) {
    return <LoadingMapCard />
  }

  if (addressQuery.isError) {
    return <ErrorMapCard onRetry={() => addressQuery.refetch()} />
  }

  if (!addressQuery.data) {
    return (
      <>
        <AddressNotFoundMapMessage isActive={props.isLast} onSuggestionSelected={props.onSuggestionSelected} />
      </>
    )
  }

  const prependedMessages = getMapPrependedMessages(props.backendInput)

  return (
    <>
      {prependedMessages.messages.map((message, index) => (
        <Message key={index} from='bot'>
          {message.text}
        </Message>
      ))}
      <CoverageCardSwitch addressResult={addressQuery.data} backendInput={prependedMessages.updatedInput} />
      <ChatStepComponent
        step={getCoverageMapToolDefaultSuggestionsMessage()}
        onSuggestionSelected={props.onSuggestionSelected}
        status={props.isLast ? 'active' : 'answered'}
      />
    </>
  )
}

function CoverageCardSwitch(props: {
  backendInput: CoverageMapCardBackendInput
  addressResult: { address: Address; types: string[] }
}) {
  const title = props.backendInput.networkType
    ? `${getNetworkTypeText(props.backendInput.networkType)} Coverage for ${
        props.addressResult.address.label ?? props.addressResult.address.addressName
      }`
    : undefined
  const requestedCarrierId =
    props.backendInput.carrierStatus === 'valid' ? props.backendInput.carrierId ?? undefined : undefined

  if (props.backendInput.wantsBestCarrier) {
    return (
      <CoverageCard
        address={props.addressResult.address}
        technology={getNetworkTypeText(props.backendInput.networkType)}
        title={title}
      />
    )
  }

  if (props.addressResult.types.some((type) => isGeocoderType(type))) {
    return (
      <RegionCoverageMapCard
        address={props.addressResult.address}
        requestedCarrierId={requestedCarrierId}
        technology={getNetworkTypeText(props.backendInput.networkType)}
        title={title}
      />
    )
  }

  return (
    <CoverageMapCard
      address={props.addressResult.address}
      requestCarrierId={requestedCarrierId}
      technology={getNetworkTypeText(props.backendInput.networkType)}
      title={title}
    />
  )
}

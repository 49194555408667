import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { Auth, Hub } from 'aws-amplify'
import React from 'react'
import { useQueryClient } from 'react-query'
import shallow from 'zustand/shallow'
import { PROFILE_QUERY_KEY } from '../../pages/home/hooks/useBackendUser'
import { freeChatQueryKey } from '../../pages/llm/free/hooks/useFreeChat'
import { FreeChatSourceSet } from '../../pages/llm/utils/source-sets'
import useAuthStore, { CurrentUser } from '../../store/auth'
import { setAmplitudeUserId } from '../../utils/amplitude'
import { Logger } from '../../utils/Logger'

export const getCurrentUser = async (auth: typeof Auth = Auth): Promise<null | CurrentUser> => {
  try {
    const user: CognitoUser = await auth.currentAuthenticatedUser()
    const attributes = await new Promise<CognitoUserAttribute[] | undefined>((res, rej) => {
      user.getUserAttributes((error, result) => {
        if (error) {
          rej(error)
          return
        }
        res(result)
      })
    })

    const email = attributes!.find((attr) => attr.Name === 'email')!.Value
    const token = attributes!.find((attr) => attr.Name === 'email')!.Value

    return { email, token }
  } catch (error) {
    Logger.error(error)
    return null
  }
}

function useUploadLocalFreeChat() {
  const queryClient = useQueryClient()

  return React.useCallback(async () => {
    const [localData, remoteData] = await Promise.all([
      FreeChatSourceSet.localSource.getStorage(),
      FreeChatSourceSet.remoteSource.getStorage()
    ])
    if ((localData?.timestamp ?? 0) > (remoteData?.timestamp ?? 0)) {
      await FreeChatSourceSet.remoteSource.setStorage(localData)
      await queryClient.invalidateQueries(freeChatQueryKey)
    }
  }, [queryClient])
}

namespace AmplifyComponent {
  export interface Props {
    hub: typeof Hub
    auth: typeof Auth
  }
}

function AmplifyComponent(props: AmplifyComponent.Props) {
  const [setCurrentUser] = useAuthStore((store) => [store.setCurrentUser], shallow)
  const queryClient = useQueryClient()
  const uploadLocalFreeChat = useUploadLocalFreeChat()

  React.useEffect(() => {
    const removeListener = props.hub.listen('auth', async ({ payload }) => {
      Logger.debug('auth event', payload)
      const { event } = payload
      if (event === 'signIn') {
        setAmplitudeUserId(payload.data.signInUserSession.accessToken.payload.sub)
        const user = await getCurrentUser(props.auth)
        setCurrentUser(user)
        await queryClient.invalidateQueries(PROFILE_QUERY_KEY)
        uploadLocalFreeChat()
      } else if (event === 'signOut') {
        setCurrentUser(null)
      }
    })

    getCurrentUser(props.auth).then((user) => {
      setCurrentUser(user)
    })

    return () => {
      removeListener()
    }
  }, [setCurrentUser, props.hub, props.auth, queryClient, uploadLocalFreeChat])

  return null
}

export default AmplifyComponent

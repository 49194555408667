import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import { arrowForward, wifi } from 'ionicons/icons'
import { NetworkMeterRecord } from '../../../services/NetworkMeter.service'
import { formatValue } from './Measurements/Measurements'
import { MeterHistoryTableStyles } from './MobileBrowserNetworkMeter.styles'
import MeterResultSummaryModal from './MeterResultSummaryModal'
import React from 'react'

const getDateFormat = (date?: string) => {
  if (!date) return ''
  const dateObj = new Date(date)
  return dateObj.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  })
}

interface MeterHistoryTableProps {
  history: NetworkMeterRecord[]
}

export const MeterHistoryTable = ({ history }: MeterHistoryTableProps) => {
  const [isRecordSummaryOpen, setIsRecordSummaryOpen] = React.useState(false)
  const [selectedRecord, setSelectedRecord] = React.useState<NetworkMeterRecord | undefined>(undefined)
  const openRecordSummary = (record: NetworkMeterRecord) => {
    setSelectedRecord(record)
    setIsRecordSummaryOpen(true)
  }

  const reversedHistory = Array.from(history).reverse()
  return (
    <>
      <div className={MeterHistoryTableStyles}>
        <IonGrid className='table'>
          <IonRow className='row header'>
            <IonCol size='2' sizeMd='2' className='column type-header'>
              Type
            </IonCol>
            <IonCol size='4' sizeMd='5' className='column date-header'>
              Date
            </IonCol>
            <IonCol size='2' className='column speed-metric-header'>
              <IonIcon className='metric-icon' src='/assets/icons/signal-meter/download.svg' size='small' />
            </IonCol>
            <IonCol size='2' className='column speed-metric-header'>
              <IonIcon className='metric-icon' src='/assets/icons/signal-meter/upload.svg' />
            </IonCol>
            <IonCol size='5' className='column address-header'>
              Location
            </IonCol>
            <IonCol size='2' className='columns'></IonCol>
          </IonRow>
          {reversedHistory.map((record) => (
            <IonRow
              key={`${record.placeId}-${record.capturedOn}`}
              className='row'
              onClick={() => openRecordSummary(record)}
            >
              <IonCol size='2' sizeMd='2' className='column type'>
                {record.connectionType === 'Wi-Fi' ? (
                  <IonIcon className='type-icon' icon={wifi} />
                ) : (
                  <IonIcon className='type-icon' src='/assets/icons/signal-meter/cellular.svg' />
                )}
              </IonCol>
              <IonCol size='4' sizeMd='5' className='column date' title={getDateFormat(record.capturedOn)}>
                {getDateFormat(record.capturedOn).replace('/', '/\u200B')}
              </IonCol>
              <IonCol size='2' className='column speed-metric'>
                {formatValue(record.downloadSpeedMbps)}
              </IonCol>
              <IonCol size='2' className='column speed-metric'>
                {formatValue(record.uploadSpeedMbps)}
              </IonCol>
              <IonCol size='5' className='column address' title={record.address}>
                {record.address ?? 'None'}
              </IonCol>
              <IonCol size='2' className='column arrow'>
                <IonIcon className='arrow-icon' icon={arrowForward} />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </div>
      <MeterResultSummaryModal
        isOpen={isRecordSummaryOpen}
        record={selectedRecord}
        onDismiss={() => {
          setIsRecordSummaryOpen(false)
          setSelectedRecord(undefined)
        }}
      />
    </>
  )
}

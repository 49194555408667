import { css } from '@emotion/css'
import { MeterHistoryTable } from '../../../../../components/SignalMeter/MobileBrowserNetworkMeter/MeterHistoryTable'
import { useNetworkMeterRecords } from '../../../../../services/NetworkMeter.service'
import { MeterToolBackendInput } from '../../../tools/handlers/MeterToolHandler'
import { IonRouterLink } from '@ionic/react'
import { MainNavigationPaths } from '../../../../../paths'

const NetworkMeterHistoryStyles = css`
  min-height: 320px;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  border: 2px solid var(--ion-color-dark);
  display: flex;
  flex-direction: column;
  gap: 16px;

  .no-history {
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    .barry-whoops {
      max-height: 180px;
    }
    > span {
      font-size: 16px;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 900;
  }

  .view-all {
    font-size: 14px;
    font-weight: 600;
    color: var(--ion-color-dark);
    text-decoration: underline;
    margin-top: auto;
  }
`

interface NetworkMeterHistoryCardProps {
  backendInput: MeterToolBackendInput
}

export function NetworkMeterHistoryCard({ backendInput }: NetworkMeterHistoryCardProps) {
  const networkMeterHistory = useNetworkMeterRecords.useNetworkMeterRecords()

  return (
    <div className={NetworkMeterHistoryStyles}>
      {/* This case should never happen, but it's good to have a fallback */}
      {networkMeterHistory.data?.length === 0 ? (
        <div className='no-history'>
          <img src='/assets/images/barry/goji_barry_whoops.svg' alt='Barry whoops' className='barry-whoops' />
          <span>It looks like you haven't run any network tests yet.</span>
        </div>
      ) : (
        <>
          <span className='title'>Recent network results</span>
          {networkMeterHistory.data && <MeterHistoryTable history={networkMeterHistory.data.slice(0, 3)} />}
          <IonRouterLink className='view-all' routerLink={MainNavigationPaths.meter}>
            View all network tests
          </IonRouterLink>
        </>
      )}
    </div>
  )
}

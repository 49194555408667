import React from 'react'
import { UseMutationResult } from 'react-query'

export function useMutationBuffer<T>(getState: () => T[], mutation: UseMutationResult<any, any, T[], any>) {
  const mutationQueue = React.useRef<(() => Promise<any>)[]>([])
  const mutationBuffer = React.useRef<{ action: 'push' | 'replace'; state: T[] }[]>([])
  const timeout = React.useRef<number | undefined>()

  const pushToBuffer = React.useCallback(
    (options: { action: 'push' | 'replace'; state: T[] }) => {
      if (timeout.current !== undefined) {
        clearTimeout(timeout.current)
      }

      mutationBuffer.current.push(options)

      timeout.current = window.setTimeout(readBuffer, 0)

      function readBuffer() {
        let newState = Array.from(getState())

        mutationBuffer.current.forEach((options) => {
          if (options.action === 'push') {
            newState.push(...options.state)
          } else if (options.action === 'replace') {
            newState = options.state
          }
        })

        mutationBuffer.current = []

        const mutationCaller = async () => {
          await mutation.mutateAsync(newState)
          mutationQueue.current.shift()
          mutationQueue.current[0]?.()
        }

        mutationQueue.current.push(mutationCaller)
        if (mutationQueue.current.length === 1) {
          mutationCaller()
        }
      }
    },
    [getState, mutation]
  )

  return { pushToBuffer }
}

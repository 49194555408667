import * as React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Logger } from './utils/Logger'
import { initRum } from './utils/aws-rum'
import { setScrollbarWidthCSSVariable } from './utils/styles/detect-scrollbar-width'

initRum()
setScrollbarWidthCSSVariable()

import('./utils/amplitude')
  .then(({ initAmplitude }) => {
    initAmplitude()
  })
  .catch(Logger.error)

import('./plugin/AppsFlyerPlugin')
  .then(({ initAppsFlyer }) => {
    initAppsFlyer()
  })
  .catch(Logger.error)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { Capacitor, registerPlugin } from '@capacitor/core'
import { Logger } from '../utils/Logger'

export interface M2CatalystSdkPluginOptions {
  requestBackgroundLocation(): Promise<{ isGranted: boolean; shouldShowRationale: boolean }>
  requestLocationPermissions(): Promise<{ isGranted: boolean; shouldShowRationale: boolean }>
  hasBackgroundLocation(): Promise<{ isGranted: boolean }>
  hasLocationPermissions(): Promise<{ isGranted: boolean }>
  pluginInitSdk(): Promise<void>
}

const M2CatalystSdkPlugin = registerPlugin<M2CatalystSdkPluginOptions>('M2CatalystSdkPlugin')

if (Capacitor.getPlatform() === 'android') {
  M2CatalystSdkPlugin.pluginInitSdk().catch((error) => {
    Logger.error('Cannot init M2 sdk:', error)
  })
}

export default M2CatalystSdkPlugin

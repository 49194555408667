import React from 'react'
import { IonPage } from '@ionic/react'
import { ChatInteraction, PredefinedChatStep, IChatFlow, MessageType } from './types/ChatStep'
import API from '../../api'
import { useMutation } from 'react-query'
import { ChatbotContentContainerStyles, ChatbotPageStyles } from './ChatbotPage.styles'
import { ChatComponent } from './components/ChatComponent'
import { ChatFlow, PredefinedChatMessageRecord } from './ChatFlow'
import { useHistory, useLocation } from 'react-router'
import { useAutoChatStorage } from './hooks/useChatStorage'
import useAddressPredictions from '../../utils/hooks/usePredictions'
import { useBoundStore } from '../../store'
import { getAddressFromPlaceId } from '../../utils/Address'
import { RoutePaths } from '../../paths'
import { ValidatedResults } from './ServerResponse'
import { useFakeLoading } from './hooks/useFakeLoading'
import { usePreloadImages } from '../../utils/hooks/usePreloadImages'
import { OnMessagePayload, usePredefinedChat } from './hooks/useChat'
import { trackAmplitudeEvent } from '../../utils/amplitude'
import { cx } from '@emotion/css'

function useSendAnswerToLLM() {
  const { search } = useAddressPredictions({ value: '' })
  const setAddress = useBoundStore((state) => state.setAddress)

  const saveAddress = async (searchTerm: string): Promise<boolean> => {
    const result = await search(searchTerm)
    if (!result.length) {
      return false
    }

    const address = await getAddressFromPlaceId(result[0].place_id)
    if (!address) {
      return false
    }

    setAddress({
      ...address,
      label: searchTerm
    })
    return true
  }

  return useMutation(
    'sendAnswerToLLM',
    async ({ question, userAnswer }: { question: PredefinedChatStep; userAnswer: string }) => {
      if (!!question.interactions.length && question.interactions[0] === ChatInteraction.ADDRESS) {
        const hasSavedAddress = await saveAddress(userAnswer)
        return { resultStatus: hasSavedAddress ? ValidatedResults.VALID : ValidatedResults.FAILED }
      }

      try {
        const response: any = await API.post(question.endpoint, { answer: userAnswer })
        return response
      } catch (error) {
        return { resultStatus: ValidatedResults.FAILED, error }
      }
    }
  )
}

function useIsLoadingChat(isLLMLoading: boolean) {
  const loadingLocation = useBoundStore((state) => state.loadingLocation)
  const { fakeLoading, triggerFakeLoading, cancelFakeLoading } = useFakeLoading()

  return {
    isLoading: isLLMLoading || loadingLocation || fakeLoading,
    triggerFakeLoading,
    cancelFakeLoading
  }
}

function shouldFakeLoading(question: PredefinedChatMessageRecord<IChatFlow>, chatFlow: IChatFlow) {
  const isFromBot =
    !('message' in question) || question.message.type === MessageType.STEP || question.message.from !== 'user'
  const isEndpointEmpty =
    'key' in question &&
    'endpoint' in chatFlow[question.key] &&
    (chatFlow[question.key] as PredefinedChatStep).endpoint === 'empty'
  const isTransition = 'key' in question && chatFlow[question.key].type === MessageType.TRANSITION

  return isFromBot && (isEndpointEmpty || isTransition)
}

const imagesToPreload = [
  '/assets/images/barry/barry_icon_ok_hand.svg',
  '/assets/images/barry/barry_icon_thinking.svg',
  '/assets/images/barry/barry_icon_swipe.svg'
]

function onFinishChat() {
  trackAmplitudeEvent('ChatBarry - Start Plan Swiping')
}

function ChatbotPage() {
  const sendAnswerToLLM = useSendAnswerToLLM()
  const location = useLocation()

  usePreloadImages(imagesToPreload)

  // TODO: for future iterations, when we need to handle multiple chat flows we should pass the chat flow key to the useChatStorage hook
  const { chatStorage, restartChat, onMessage: onMessageToStorage, onServerResponse } = useAutoChatStorage<IChatFlow>()

  const { isLoading, triggerFakeLoading, cancelFakeLoading } = useIsLoadingChat(sendAnswerToLLM.isLoading)
  const onMessage = React.useCallback(
    async (payload: OnMessagePayload<any>) => {
      if (shouldFakeLoading(payload.messageRecord, ChatFlow) || payload.isSuggestionResponse) {
        await triggerFakeLoading()
      }
      onMessageToStorage(payload)
    },
    [onMessageToStorage, triggerFakeLoading]
  )

  const chat = usePredefinedChat({
    sendAnswerToLLM: sendAnswerToLLM.mutateAsync,
    chatFlow: ChatFlow,
    onMessage,
    onServerResponse,
    timelineRecords: chatStorage.data?.chatTimeline ?? []
  })

  const history = useHistory()
  React.useLayoutEffect(() => {
    const query = new URLSearchParams(history.location.search)
    const restart = query.get('restart')
    query.delete('restart')

    if (restart) {
      restartChat()
      history.replace(history.location.pathname + '?' + query.toString())
    }
  }, [history, restartChat])

  return (
    <IonPage className={cx(ChatbotPageStyles, ChatbotContentContainerStyles)}>
      {chatStorage.data ? (
        <ChatComponent
          endLink={RoutePaths.planSwipe + location.search}
          onRestart={() => {
            cancelFakeLoading()
            restartChat()
            history.push(RoutePaths.chatFree)
          }}
          isLoading={isLoading}
          chatStatus={chatStorage.data.chatStatus}
          chat={chat}
          onChatEnd={onFinishChat}
        />
      ) : null}
    </IonPage>
  )
}

export default ChatbotPage

import { CarrierRow } from './CoverageMapCard'
import { Address } from '../../store/address'
import { UseCarrier } from '../../services/Carrier.service'
import { IonCol, IonGrid, IonRouterLink, IonRow } from '@ionic/react'
import { SignalMeterStrength } from '../SignalMeter/SignalMeterStrength'
import { NetworkPerformanceLabel } from '../SignalStrengthLabel'
import { ChartStyles, CoverageMapCardStyles } from './CoverageMapCard.styles'
import { cx } from '@emotion/css'

export interface CoverageCardProps {
  address: Address
  technology?: '5G' | '4G'
  title?: string
}

const CoverageCard = ({ address, technology, title }: CoverageCardProps) => {
  const bestCarrier = UseCarrier.useBestCarrierWithPerformance({ addresses: [address] }).data
  const performance =
    technology === '5G'
      ? bestCarrier?.networkPerformance.average5GPerformance
      : bestCarrier?.networkPerformance.average4GPerformance

  return (
    <div className={cx(CoverageMapCardStyles, 'only-coverages')}>
      <span className='title'>{title ?? address?.label ?? address?.addressName}</span>
      <div className='subtitle'>
        <span className='text'>{address?.addressName}</span>
      </div>

      <IonGrid className={ChartStyles}>
        {!!bestCarrier && (
          <CarrierRow
            title='Recommended'
            carrier={bestCarrier.carrier}
            technology={'Both'}
            performance4G={bestCarrier.networkPerformance.average4GPerformance}
            performance5G={bestCarrier.networkPerformance.average5GPerformance}
          />
        )}
        <IonRow className='description'>
          <IonCol className='performance-title'>
            {`${technology} Network coverage: `}
            <NetworkPerformanceLabel networkPerformance={performance ?? 0} />
          </IonCol>
        </IonRow>
        <IonRow className='signal-meter'>
          <SignalMeterStrength quality={performance ?? 0} />
        </IonRow>
      </IonGrid>

      <IonRouterLink
        routerLink={`/map?technology=${technology}&carrier=${bestCarrier?.carrier?.id}&placeId=${address.placeId}`}
        routerDirection='forward'
        color='dark'
        className='map-link'
      >
        View in map
      </IonRouterLink>
    </div>
  )
}

export default CoverageCard

import { ChatFlowSelector } from '../../ChatFlow'
import { ChatInteraction, MessageType } from '../../types/ChatStep'
import { FreeBotMessage } from '../../types/free-chat.types'
import { ChatInterface, ToolHandler } from '../const/tool.const'

export type DefaultSuggestionToolHandlerBackendInput = {
  lastUsedTool: string | null
  params: {
    wasNegativeAnswer: boolean
  }
}

export class DefaultSuggestionToolHandler implements ToolHandler<DefaultSuggestionToolHandlerBackendInput> {
  constructor(private chatInterface: ChatInterface<DefaultSuggestionToolHandlerBackendInput>) {}

  private onMessage(message: FreeBotMessage) {
    return this.chatInterface.onMessage({
      messageRecord: {
        message
      }
    })
  }

  handleUnknownTool() {
    this.onMessage({
      from: 'bot',
      question: [{ text: 'Sorry, this is still a work in progress. Please try again later.' }],
      type: MessageType.STEP,
      suggestions: ChatFlowSelector.question__start_plan_finder_free.suggestions,
      interactions: [ChatInteraction.FREE_TEXT]
    })
  }

  handle(): void {
    this.onMessage({
      from: 'bot',
      question: [{ text: 'Okay! Is there anything else I can help with?' }],
      type: MessageType.STEP,
      suggestions: ChatFlowSelector.question__start_plan_finder_free.suggestions,
      interactions: [ChatInteraction.FREE_TEXT]
    })
  }
}

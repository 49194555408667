import { css } from '@emotion/css'

export const FooterStyles = css`
  padding: 16px 18px;
  position: relative;
  box-shadow: none;
  border-top: 2px solid var(--ion-color-light-shade);

  .finish-button::part(native) {
    font-size: 16px;
    font-weight: 600;
  }
`

export const ChatInputStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  .send-button {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .send-image {
    max-width: 24px;
  }

  .spinner {
    min-width: 16px;
  }
`

export const predictionsStyles = css`
  position: absolute;

  display: flex;
  flex-direction: column;
  bottom: calc(76px + var(--ion-safe-area-bottom));
  &.with-keyboard {
    bottom: 76px;
  }
  left: 50%;
  transform: translateX(calc(-50% - 40px));
  width: 100%;
  min-width: 128px;
  max-width: calc(100% - 128px);
  @media (min-width: 836px) {
    max-width: 704px;
  }
  z-index: 100;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: opacity 0.2s;
  opacity: 1;
  pointer-events: auto;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`

import * as React from 'react'
import { css } from '@emotion/css'
const SwiperContentStyles = css`
  padding-bottom: 20px;
`

export const FreeBulletsStyles = (paddingTop: number) => css`
  .swiper-pagination {
    position: relative;
    top: 0 !important;
    padding-top: var(--bullets-padding-top, ${paddingTop}px);
  }
`

export function SwiperContent(props: { children?: React.ReactNode | React.ReactNode[] }) {
  return <div className={SwiperContentStyles}>{props.children}</div>
}

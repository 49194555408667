import { FreshRedirectionSymbol } from '../../../tools/handlers/utils/MessageRedirection'
import { ChatInteraction, ChatStep, MessageType } from '../../../types/ChatStep'
import { Suggestion } from '../../../types/Suggestion'
import ChatStepComponent from '../../ChatQuestionComponent'
import Message from '../../Message'

type RedirectionDescriptor = {
  redirectingText: string
  question: ChatStep
}
function useRedirectionDescriptor(
  backendInput: RedirectionToolComponentProps['backendInput']
): RedirectionDescriptor | null {
  if (backendInput.action === 'static') {
    return backendInput.params.descriptor
  }

  if (backendInput.action === 'dynamic-mapped' && backendInput.params.behavior === 'plan-finder-status') {
    return {
      question: {
        question: [{ text: 'Welcome back! What can I help you with?' }],
        from: 'bot',
        type: MessageType.STEP,
        suggestions: [],
        interactions: [ChatInteraction.FREE_TEXT]
      },
      redirectingText: 'Got it! Taking you to the Plan Finder...'
    }
  }

  return null
}

export type RedirectionToolComponentProps = {
  onSuggestionSelected: (suggestion: Suggestion) => void
  isLast: boolean
  backendInput:
    | {
        action: 'static'
        params: { [FreshRedirectionSymbol]?: boolean; descriptor: RedirectionDescriptor }
      }
    | {
        action: 'dynamic-mapped'
        params: { [FreshRedirectionSymbol]?: boolean; behavior: 'plan-finder-status' }
      }
}
export function RedirectionToolComponent(props: RedirectionToolComponentProps) {
  const descriptor = useRedirectionDescriptor(props.backendInput)

  if (descriptor === null) {
    return <Message from='bot'>Something went wrong... URL cannot be interpreted</Message>
  }

  if (props.backendInput.params[FreshRedirectionSymbol]) {
    return <Message from='bot'>{descriptor.redirectingText}</Message>
  }

  return (
    <ChatStepComponent
      onSuggestionSelected={props.onSuggestionSelected}
      step={descriptor.question}
      status={props.isLast ? 'active' : 'answered'}
    />
  )
}

import { SMS_UNLIMITED, VOICE_UNLIMITED } from './Preferences'
import { PlanPreferences, withinUserPreferences } from './getRecommendedPlans'
import { Addon, Plan, PlanResponse } from './Plan'
import { FilterOptions } from './Plan.service'

export const mapPlanResponseToPlan = (plan: PlanResponse): Plan => {
  return {
    planId: plan.id,
    carrierId: plan.carrierId || '',
    carrierName: plan.carrierName || '',
    totogiCarrierName: plan.totogiCarrierName || '',
    planName: plan.name || '',
    shortPlanName: plan.shortPlanName || '',
    planLink: plan.planLink || '',
    price: plan.monthlyPrice || 0,
    priceCurrency: plan.priceCurrency || '',
    telcoType: plan.telcoType || 'UNKNOWN',
    data: plan.dataLimit || 0,
    addons: plan.addons as Addon[],
    sms: plan.isSmsUnlimited ? SMS_UNLIMITED : plan.smsAllowanceTop || 0,
    voice: plan.isVoiceUnlimited ? VOICE_UNLIMITED : plan.voiceMinutesTop || 0,
    hotspotDataLimit: plan.hotspotDataLimit,
    hotspotDataLimitUnits: plan.hotspotDataLimitUnits,
    isHotspotUnlimited: plan.isHotspotUnlimited,
    priceMultiLine: '',
    premiumData: plan.premiumData || 0,
    isPremiumDataUnlimited: plan.isPremiumDataUnlimited || false,
    premiumDataUnits: plan.premiumDataUnits || '',
    roamingCountries: plan.roamingCountries || [],
    streamingServices: plan.streamingServices || [],
    video_streaming_quality: '',
    networkOperators: plan.networkOperators,
    costPerLineScore: plan.costPerLineScore,
    dataAllowanceScore: plan.dataAllowanceScore,
    popularityScore: plan.popularityScore,
    perksScore: plan.perksScore,
    telcoTypeScore: plan.telcoTypeScore,
    nsc4gScore: plan.nsc4gScore,
    nsc5gScore: plan.nsc5gScore,
    overall4gScore: plan.overall4gScore,
    overall5gScore: plan.overall5gScore,
    costPerLine: plan.costPerLine,
    numLines: plan.numLines,
    isDataUnlimited: plan.isDataUnlimited,
    affiliateLink: plan.affiliateLink,
    contractType: plan.contractType,
    contractMonthLength: plan.contractMonthLength,
    deals: plan.deals ?? []
  }
}

export function filterPlans(
  plans: Plan[],
  filters?: FilterOptions & { withinUserPreferences?: { preferences: PlanPreferences; fuzzy?: boolean } }
) {
  if (!filters) {
    return plans
  }

  if (filters.nLinesFilter) {
    plans = plans.filter((plan) => plan.numLines === filters.nLinesFilter)
  }

  if (filters.withinUserPreferences && filters.withinUserPreferences.preferences) {
    const preferences = filters.withinUserPreferences.preferences
    if (filters.withinUserPreferences.fuzzy) {
      plans = plans.filter((plan) => !withinUserPreferences(plan, preferences))
    } else {
      plans = plans.filter((plan) => withinUserPreferences(plan, preferences))
    }
  }

  // This filter should always be the last one
  if (filters.nPlansFilter) {
    plans = plans.slice(0, filters.nPlansFilter)
  }

  return plans
}

function cleanString(str: string) {
  return str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
}

export type SearchPlan = Pick<
  Plan,
  'planName' | 'carrierName' | 'shortPlanName' | 'price' | 'sms' | 'isDataUnlimited' | 'voice'
>

function compareEquals<P extends SearchPlan>(plan: P, search: string) {
  const cleanedSearch = cleanString(search)

  return (
    cleanString(plan.planName) === cleanedSearch ||
    cleanString(plan.carrierName) === cleanedSearch ||
    cleanString(plan.shortPlanName) === cleanedSearch ||
    plan.price.toString() === cleanedSearch ||
    (cleanedSearch.includes('unlimited') &&
      (plan.isDataUnlimited || plan.sms === SMS_UNLIMITED || plan.voice === VOICE_UNLIMITED))
  )
}

function terms(str: string): string[] {
  return str.toLowerCase().split(/\s+/)
}

function comparePartial<P extends SearchPlan>(plan: P, search: string) {
  const threshold = 0.6

  const planTerms = [terms(plan.carrierName), terms(plan.shortPlanName), terms(plan.planName)]
  const searchTerms = terms(search.trim())

  const wasUnlimitedPresent = searchTerms.includes('unlimited')
  if (wasUnlimitedPresent) {
    searchTerms.splice(searchTerms.indexOf('unlimited'), 1)
  }

  const matches = searchTerms.filter((word) => planTerms.some((termsChunk) => termsChunk.includes(word))).length || 0

  const proportion = matches / searchTerms.length

  return (
    proportion >= threshold ||
    plan.price.toString().includes(search!.replace('$', '')) ||
    (searchTerms.includes('unlimited') &&
      proportion >= threshold &&
      (plan.isDataUnlimited || plan.sms === SMS_UNLIMITED || plan.voice === VOICE_UNLIMITED))
  )
}
export function searchPlans<P extends SearchPlan>(plans: P[], search?: string) {
  if (!search) {
    return plans
  }

  const compare = search[0] === '=' ? compareEquals : comparePartial
  return plans.filter((plan) => compare(plan, search))
}

import { Amplify } from 'aws-amplify'
import { Browser } from '@capacitor/browser'
import awsconfig from '../../aws-exports'
import { Capacitor } from '@capacitor/core'
import AmplifyComponent from './AmplifyComponent'

awsconfig.oauth.redirectSignIn = `${window.location.origin}/`
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`
if (Capacitor.getPlatform() !== 'web') {
  // TODO: get from env or use only one url in config
  awsconfig.oauth.redirectSignIn = 'com.gojimobile.app://com.gojimobile.app/'
  awsconfig.oauth.redirectSignOut = 'com.gojimobile.app://com.gojimobile.app/'
}

async function urlOpener(url: string, _redirectUrl: string) {
  await Browser.open({ url, windowName: '_self' })
}

Amplify.configure({
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    urlOpener
  }
})

export default AmplifyComponent

import * as React from 'react'
import { useDeepCompareEffectForMaps } from '../../utils'
import useHighlightRegion from './useHighlightRegion'

const MAP_ID = process.env.REACT_APP_MAP_ID

interface MapProps extends google.maps.MapOptions {
  hideRecenterButton?: boolean
  center?: google.maps.LatLng | google.maps.LatLngLiteral
  zoom: number
  children?: React.ReactNode
  bounds?: google.maps.LatLngBounds
  customizeMap?: (map: google.maps.Map) => void
  className?: string
  highlightedRegionPlaceId?: string
}

export const USA_BOUNDS = {
  west: -180,
  south: -20,
  east: -40,
  north: 80
}

const MapComponent: React.FC<MapProps> = ({
  center,
  zoom,
  children,
  bounds,
  hideRecenterButton,
  customizeMap,
  className,
  highlightedRegionPlaceId,
  ...options
}) => {
  const [map, setMap] = React.useState<google.maps.Map>()

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options)
      if (bounds) map.fitBounds(bounds)
    }
  }, [map, options])

  const initializeMap = React.useCallback((mapElement: HTMLDivElement) => {
    if (!mapElement) {
      return
    }
    const newMap = new google.maps.Map(mapElement, {
      center: center || new google.maps.LatLng(39.833333, -98.585522),
      zoom,
      minZoom: options.minZoom ?? 4,
      mapId: MAP_ID,
      disableDefaultUI: true,
      gestureHandling: options.gestureHandling
    })
    customizeMap?.(newMap)
    setMap(newMap)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useHighlightRegion(map, highlightedRegionPlaceId)

  return (
    <>
      <div ref={initializeMap} id='map' className={className} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map })
        }
      })}
    </>
  )
}

export default MapComponent

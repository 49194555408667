import * as React from 'react'
import { isPlatform } from '@ionic/react'
import { useQuery } from 'react-query'
import { Network } from '@capacitor/network'

export const useNetworkTypeQuery = () => {
  const networkTypeQuery = useQuery({
    queryKey: 'networkTypeQuery',
    queryFn: async () => {
      if (isPlatform('ios')) {
        const status = await Network.getStatus()
        return status.connectionType
      }

      const navigatorConnection = (window.navigator as any).connection
      const connectionType = (navigatorConnection?.type as string) || ''
      const effectiveType = (navigatorConnection?.effectiveType as string) || ''

      if (isPlatform('desktop') && !connectionType) {
        return 'wifi'
      }

      return effectiveType || connectionType
    }
  })

  React.useEffect(() => {
    Network.addListener('networkStatusChange', () => {
      networkTypeQuery.refetch()
    })
  }, [networkTypeQuery])

  return networkTypeQuery
}

export const formatNetworkType = (networkType: string) => {
  if (networkType === 'unknown') {
    return
  }
  if (networkType === 'wifi' || networkType === 'wimax') {
    return 'Wi-Fi'
  }

  if (networkType === 'cellular') {
    return 'Cellular'
  }

  if (networkType === 'none') {
    return 'No connection'
  }

  return networkType.toUpperCase()
}

import { css } from '@emotion/css'
import { MOBILE_BREAKPOINT } from '../../utils/hooks/useWindowSize'

export const AddressBookPageStyles = css`
  color: var(--ion-color-dark);
  height: 100%;

  .content {
    &::part(scroll) {
      padding: 30px;

      @media (max-width: 450px) {
        padding: 16px;
      }
      padding-top: 32px;

      @media (min-width: ${MOBILE_BREAKPOINT}px) {
        padding-left: 8.5%;
        padding-right: 8.5%;
      }
    }
  }

  .section-container-description {
    display: inline-block;
    padding-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }
`

export const MobileHeaderStyles = css`
  width: 100%;
  background-color: var(--goji-primary-lime);
  border-bottom: 2px solid var(--ion-color-dark);
  height: var(--header-full-size);
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: calc(env(safe-area-inset-top));
  // Start / End icons
  .side-element {
    width: 32px;
  }

  // Title
  span {
    font-size: 18px;
    font-weight: 900;
  }
`

export const DesktopHeaderStyles = css`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px 16px;

  .back-button {
    width: 20px;
    height: 20px;
    ion-icon {
      width: 20px;
      height: 20px;
    }
  }
`

export const addressBookListStyles = css`
  height: 100%;
  overflow-y: auto;

  .address-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;

    &:first-child {
      border-top: 1px solid var(--ion-color-light);
    }
    border-bottom: 1px solid var(--ion-color-light);
    .address {
      width: 100%;
      .address-info {
        display: flex;
        flex-direction: column;
        padding: 16px 0;
        .address-label {
          font-size: 16px;
          font-weight: 500;
        }
        .address-detail {
          font-size: 14px;
          color: var(--ion-color-medium);
        }
      }
    }
  }

  .remove-button {
    height: 50px;
    width: 50px;
  }
`

export const PopoverListStyles = css`
  .remove-item {
    color: var(--ion-color-danger);
  }

  ::part(content) {
    box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
`

export const BarryContainerStyles = css`
  margin-top: 80px;
  @media (max-height: 700px) {
    margin-top: 40px;
  }
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  ion-img::part(image) {
    height: 196px;
  }

  h2 {
    color: var(--ion-color-medium);
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 8px;
  }

  span.subheading {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin-bottom: 8px;
  }

  span.paragraph {
    color: var(--ion-color-medium);
    font-size: 12px;
  }
`

export const AddressBookModalStyles = css`
  --width: fit-content;
  --max-width: 400px;
  --height: fit-content;
  --border-radius: 10px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    --max-width: 300px;
  }

  @media (max-width: 320px) {
    --max-width: 280px;
  }
`

function IconTag(props: { className?: string }) {
  return (
    <svg
      className={props.className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.7073 11.622L12.6073 3.522C12.2833 3.198 11.8333 3 11.3383 3H5.03828C4.04828 3 3.23828 3.81 3.23828 4.8V11.1C3.23828 11.595 3.43628 12.045 3.76928 12.378L11.8693 20.478C12.1933 20.802 12.6433 21 13.1383 21C13.6333 21 14.0833 20.802 14.4073 20.469L20.7073 14.169C21.0403 13.845 21.2383 13.395 21.2383 12.9C21.2383 12.405 21.0313 11.946 20.7073 11.622ZM13.1383 19.209L5.03828 11.1V4.8H11.3383V4.791L19.4383 12.891L13.1383 19.209Z'
        fill='currentColor'
      />
      <path
        d='M7.23828 8C7.79057 8 8.23828 7.55228 8.23828 7C8.23828 6.44772 7.79057 6 7.23828 6C6.686 6 6.23828 6.44772 6.23828 7C6.23828 7.55228 6.686 8 7.23828 8Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default IconTag

import { css } from '@emotion/css'
import { IonSpinner } from '@ionic/react'
import React from 'react'
import { UserTextMessage, MessageType, outOfContextTemplate, ChatStep } from '../types/ChatStep'
import { Suggestion } from '../types/Suggestion'
import ChatStepComponent from './ChatQuestionComponent'
import Message from './Message'
import { TransitionMessageComponent } from './TransitionMessage'
import SuggestionComponent from './SuggestionComponent'
import { ToolComponent } from '../tools/Tool'
import { FreeBotMessage } from '../types/free-chat.types'

const ChatTimelineStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
`

const MessageLoadingStyles = css`
  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .chat-box {
    padding: 0 16px;
  }
`

export interface ChatTimelineProps {
  timeline: (FreeBotMessage | UserTextMessage)[]
  isLoading: boolean
  onSuggestionSelected: (suggestion: Suggestion) => void
  lastQuestion: ChatStep | undefined
}
function ChatTimeline(props: ChatTimelineProps) {
  return (
    <div aria-live='polite' role='log' className={ChatTimelineStyles}>
      {props.timeline.map((message, idx) => {
        if (message.type === MessageType.STEP) {
          return (
            <ChatStepComponent
              onSuggestionSelected={props.onSuggestionSelected}
              key={idx}
              step={message}
              status={idx === props.timeline.length - 1 ? 'active' : 'answered'}
            />
          )
        } else if (message.type === MessageType.TRANSITION) {
          return <TransitionMessageComponent message={message} key={idx} />
        } else if (message.type === MessageType.TOOL) {
          return (
            <ToolComponent
              key={idx}
              isLast={idx === props.timeline.length - 1}
              message={message}
              onSuggestionSelected={props.onSuggestionSelected}
            />
          )
        } else if (message.text.startsWith(outOfContextTemplate)) {
          const lastStepSuggestions = props.lastQuestion?.suggestions ?? []
          return (
            <>
              <Message key={idx} from={message.from}>
                {message.text}
              </Message>
              {idx === props.timeline.length - 1 ? (
                <div className='suggestions'>
                  {lastStepSuggestions.map((suggestion, idx) => (
                    <SuggestionComponent
                      onSuggestionSelected={props.onSuggestionSelected}
                      key={'suggestion-' + idx}
                      suggestion={suggestion}
                    />
                  ))}
                </div>
              ) : null}
            </>
          )
        } else {
          if (message.text.includes('**') && Array.from(message.text.matchAll(/\*\*/g)).length % 2 === 0) {
            return (
              <Message key={idx} from={message.from}>
                {message.text.split('**').map((text, idx) => {
                  return idx % 2 === 0 ? <span key={idx}>{text}</span> : <strong key={idx}>{text}</strong>
                })}
              </Message>
            )
          }

          return (
            <Message key={idx} from={message.from} hasBarry={message.from === 'bot' && idx === 0}>
              {message.text}
            </Message>
          )
        }
      })}
      {props.isLoading ? (
        <Message className={MessageLoadingStyles} from='bot'>
          <div aria-label='Bot is typing...' className='spinner-wrapper'>
            <IonSpinner aria-hidden name='dots' />
          </div>
        </Message>
      ) : null}
    </div>
  )
}

export default ChatTimeline

import * as React from 'react'
import { IonCol, IonGrid, IonLabel, IonRow, IonText, IonToggle } from '@ionic/react'
import { useNavigateToPlan } from '../../../../utils/hooks/useNavigateToPlan'
import { getDifferences } from '../../utils/getDifferences'
import { Plan } from '../../../../services/planService/Plan'
import { Address } from '../../../../store/address'
import { IonToggleCustomEvent, ToggleChangeEventDetail } from '@ionic/core'
import { CarrierInfoStyles } from '../../ComparePage.styles'
import { AddressRow } from '../AddressRow'
import { renderSimpleRow } from '../SimpleRow'
import { CarrierImage } from '../CarrierImage'
import { useIsMobileSize } from '../../../../utils/hooks/useWindowSize'

namespace CompareTable {
  export interface Props {
    plans: Plan[]
    addresses: Address[]
  }
}

function CompareTableFn(props: CompareTable.Props, ref: React.Ref<HTMLIonGridElement>) {
  const [highlight, setHighlight] = React.useState(true)

  const { navigateToPlan } = useNavigateToPlan()

  const differences = getDifferences(props.plans)
  const onChange = ({ detail }: IonToggleCustomEvent<ToggleChangeEventDetail<any>>) => setHighlight(detail.checked)
  const isMobile = useIsMobileSize()

  return (
    <IonGrid ref={ref}>
      {/* TOP HEADER */}
      <IonRow className='sticky-header border-bottom'>
        <IonCol>
          <div
            style={{
              position: 'absolute',
              top: '-15px',
              left: 0,
              borderBottom: '1px solid var(--ion-color-secondary-shade)',
              height: '15px',
              width: '100%',
              backgroundColor: '#fff'
            }}
          ></div>
          <IonToggle checked={highlight} color='primary' onIonChange={onChange} />
          <div>
            <IonLabel color='dark'>Highlight differences</IonLabel>
          </div>
        </IonCol>
        {differences.map((planDiff, idx) => (
          <IonCol className={CarrierInfoStyles} key={idx}>
            <div>
              <div>
                <CarrierImage carrierId={planDiff.plan.carrierId} />
              </div>
              <IonText className='smaller-font'>{planDiff.plan.shortPlanName}</IonText>
            </div>

            <button
              onClick={(evt) => {
                evt.preventDefault()
                navigateToPlan(planDiff.plan)
              }}
              className='smaller-font see-details'
            >
              See details
            </button>
          </IonCol>
        ))}
      </IonRow>
      {/* PRICE */}
      {renderSimpleRow('Price', 'price', differences, highlight, true, '$')}
      {/* SIGNAL STRENGTH */}
      <IonRow className={(isMobile ? 'sticky ' : '') + 'border-bottom'}>
        <IonCol>
          <IonText color='dark'>Network performance</IonText>
        </IonCol>
      </IonRow>
      {props.addresses.map((address) => (
        <AddressRow key={address.placeId} highlightEnabled={highlight} plans={props.plans} address={address} />
      ))}
      {/* DATA */}
      <IonRow className={(isMobile ? 'sticky ' : '') + 'border-bottom'}>
        <IonCol>
          <IonText color='dark'>Data</IonText>
        </IonCol>
      </IonRow>
      {renderSimpleRow('Data', 'data', differences, highlight)}
      {/* OTHERS */}
      <IonRow className={(isMobile ? 'sticky ' : '') + 'border-bottom'}>
        <IonCol>
          <IonText color='dark'>Others</IonText>
        </IonCol>
      </IonRow>
      {renderSimpleRow('Voice', 'voice', differences, highlight)}
      {renderSimpleRow('Text', 'sms', differences, highlight)}
    </IonGrid>
  )
}

export default React.forwardRef(CompareTableFn)

import { PermissionStatus } from '@capacitor/geolocation'
import { FreeChatBotMessageRecord } from '../types/free-chat.types'
import { MessageType } from '../types/ChatStep'
import { SuggestionType } from '../types/Suggestion'
import { ValidatedResults } from '../ServerResponse'

export function getNotGrantedLocationMessage(
  permissionState: PermissionStatus['location']
): FreeChatBotMessageRecord['message'] {
  if (permissionState === 'prompt' || permissionState === 'prompt-with-rationale') {
    return {
      from: 'bot',
      question: [{ text: 'Please enable location services to use this feature.' }],
      type: MessageType.STEP,
      interactions: [],
      suggestions: [
        {
          text: 'Enable Location',
          value: {
            parsedAnswer: 'Enable Location',
            resultStatus: ValidatedResults.VALID
          },
          type: SuggestionType.CURRENT_LOCATION
        },
        {
          text: 'Cancel',
          value: {
            parsedAnswer: 'Cancel',
            resultStatus: ValidatedResults.CANCELLED
          },
          type: SuggestionType.TEXT
        }
      ]
    }
  }
  return {
    from: 'bot',
    type: MessageType.TEXT,
    text: 'Permission Denied. Please enable location services to use this feature.'
  }
}

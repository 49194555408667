import { Capacitor } from '@capacitor/core'
import { isPlatform } from '@ionic/react'
import create from 'zustand'
import { StateCreator } from 'zustand'

export type PermissionState = {
  modalState: ModalState
}

export type PermissionActions = {
  clearPermissionState: () => void
  setModalState: (state: ModalState) => void
}

export type RejectionState = 'not-rejected' | 'need-to-ask' | 'hide' | 'show-config-button'

export function getRejectionState(granted: boolean | undefined, rationale: boolean | undefined): RejectionState {
  if (granted) {
    return 'not-rejected'
  }

  if (rationale === undefined) {
    return 'need-to-ask'
  }

  return rationale ? 'hide' : 'show-config-button'
}

export type ModalState = 'idle' | 'closed'

export const isNativeAndroid = isPlatform('android') && Capacitor.getPlatform() !== 'web'

const createNavigationSlice: StateCreator<
  PermissionState & PermissionActions,
  [],
  [],
  PermissionState & PermissionActions
> = (set, get) => ({
  modalState: 'idle',

  setModalState(modalState: ModalState) {
    set(() => ({ modalState }))
  },

  clearPermissionState() {
    get().setModalState('idle')
  }
})

const usePermissionStore = create(createNavigationSlice)

export default usePermissionStore

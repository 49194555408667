import { RECOMMENDED_TAB } from './pages/plans/tabs/PlanTab'

export const RoutePaths = {
  carrierSelector: '/carrier-selector',
  currentPlan: '/current-plan',
  otherQuestions: '/other-questions',
  addresses: '/addresses',
  addressBook: '/address-book',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  confirmationCode: '/confirmation-code',
  accountCreated: '/account-created',
  qrcodeRedirect: '/qrcode-redirect',
  reasonsForSwitch: '/reasons-for-switch',
  onboarding: '/onboarding',
  chatbot: '/chat',
  chatFinder: '/chat/finder',
  chatMenu: '/chat/menu',
  chatFree: '/chat/free',
  planSwipe: '/plan-swipe',
  compare: '/compare',
  planSwipeTransition: '/plan-swipe/transition',
  root: '/'
}

export const MainNavigationPaths = {
  home: '/home',
  meter: '/meter',
  plans: '/plans',
  recommendedPlans: '/plans/' + RECOMMENDED_TAB,
  map: '/map',
  signIn: '/sign-in',
  createAccount: '/create-account',
  results: '/addresses/results',
  profile: '/profile',
  deals: '/promotions',
  root: '/'
}

export const ProfilePaths = {
  planPreferences: '/plan-preferences',
  savedPlans: '/saved-plans',
  addressBook: '/address-book',
  about: '/about'
}

import { LDContext } from 'launchdarkly-js-client-sdk'
import { useQuery } from 'react-query'
import { Logger } from './Logger'

async function initLaunchDarkly() {
  if (!process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID) {
    Logger.warn('Goji warning: LaunchDarkly client ID not set. Not initializing LaunchDarkly.')
    return
  }

  const { asyncWithLDProvider } = await import('launchdarkly-react-client-sdk')

  const context: LDContext = {
    kind: 'user',
    key: 'anonymous'
  }

  let userInfo: any
  try {
    const Auth = (await import('aws-amplify')).Auth
    userInfo = await Auth.currentUserInfo()
  } catch (e) {}

  if (userInfo && userInfo.attributes.email && userInfo.username) {
    context.key = userInfo.username
    context.name = userInfo.attributes.email
  }

  const provider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    context,
    options: {
      logger: { debug: Logger.debug, info: Logger.debug, warn: Logger.debug, error: Logger.error }
    },
    reactOptions: {
      useCamelCaseFlagKeys: false
    }
  })
  return provider
}

export async function initFeatureFlags() {
  try {
    return await initLaunchDarkly()
  } catch (error) {
    Logger.error('Error initializing LaunchDarkly', error)
  }
}

export function useFFProviderLoader(enabled: boolean) {
  return useQuery({
    queryKey: 'feature-flags-loader',
    staleTime: Infinity,
    enabled,
    queryFn: async () => {
      const provider = await initFeatureFlags()
      return { provider }
    }
  })
}

export const FeatureFlag = {
  CHAT_UI: 'chat_ui',
  GEN_UI_PLANS: 'gen_ui_plans',
  GEN_UI_COVERAGE: 'gen_ui_coverage',
  GEN_UI_METER: 'gen_ui_meter',
  FILTERS_UI: 'filters_ui',
  DEALS: 'deals'
} as const

export type FeatureFlagType = (typeof FeatureFlag)[keyof typeof FeatureFlag]

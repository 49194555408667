import { css, cx } from '@emotion/css'
import { IonButton, IonContent, IonHeader, IonIcon, IonModal, IonPage, IonSpinner } from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import { NetworkMeterRecord, NetworkMeterService } from '../../../services/NetworkMeter.service'
import { EllipseBackgroundLong } from '../../EllipseBackground/EllipseBackgroundLong'
import MeterResultCard from './MeterResultCard'
import { useState } from 'react'
import { MobileHeaderStyles } from '../../../pages/address-book/AddressBookStyles'
import { useIsMobileSize } from '../../../utils/hooks/useWindowSize'
import { EllipseBackgroundDesktop } from '../../EllipseBackground/EllipseBackgroundDesktop'

const RecordSummaryPageStyles = css`
  .side-element {
    height: 32px;
    width: 32px;
    ion-icon {
      width: 32px;
      height: 32px;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    .remove-button {
      > ion-icon {
        width: 20px;
      }
    }
  }

  .ellipse-background {
    top: calc(env(safe-area-inset-top) - 48px);
    z-index: -1;
  }

  .content {
    --background: transparent;
    .content-wrapper {
      display: flex;
      justify-content: center;
    }
  }
`

const ModalStyles = css`
  --width: 100%;
  --height: 100%;
`

interface MeterResultSummaryModalProps {
  isOpen: boolean
  record?: NetworkMeterRecord
  onDismiss: () => void
}

const MeterResultSummaryModal = ({ isOpen, record, onDismiss }: MeterResultSummaryModalProps) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const isMobileSize = useIsMobileSize()
  async function handleDelete() {
    if (!record) return
    setIsDeleting(true)
    await NetworkMeterService.deleteNetworkMeterRecord(record.id)
    setIsDeleting(false)
    onDismiss()
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} className={ModalStyles}>
      <IonPage className={RecordSummaryPageStyles}>
        {isMobileSize ? (
          <EllipseBackgroundLong className='ellipse-background' />
        ) : (
          <EllipseBackgroundDesktop className='ellipse-background' />
        )}
        <IonHeader className={cx(MobileHeaderStyles, 'ion-no-border')}>
          <div className='side-element'>
            <IonButton fill='clear' onClick={onDismiss} className='back-button'>
              <IonIcon color='dark' src={arrowBack} />
            </IonButton>
          </div>
          <span>Result Details</span>
          <div className='side-element'>
            {isDeleting ? (
              <IonSpinner color='dark' />
            ) : (
              <IonButton fill='clear' onClick={handleDelete} className={'remove-button'}>
                <IonIcon color='dark' src={'/assets/icons/trash.svg'} />
              </IonButton>
            )}
          </div>
        </IonHeader>
        <IonContent className='content'>
          <div className='content-wrapper'>
            {record ? <MeterResultCard record={record} onDismiss={onDismiss} /> : <span>No Record Found</span>}
          </div>
        </IonContent>
      </IonPage>
    </IonModal>
  )
}

export default MeterResultSummaryModal

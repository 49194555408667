import { css, cx } from '@emotion/css'
import { createPortal } from 'react-dom'

const TModalStyles = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 368px;
  max-width: 90%;
  background-color: var(--ion-card-background);
  border-radius: 10px;
  color: var(--ion-text-color);
  padding: 30px;
`

const BackdropStyles = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--ion-color-medium);
`

interface TModalProps {
  isOpen?: boolean
  onClose?: () => void
  children?: React.ReactNode
  className?: string
}
const TModal: React.FC<TModalProps> = (props) => {
  if (props.isOpen === false) {
    return null
  }
  return createPortal(
    <>
      <div onClick={props.onClose} className={BackdropStyles} />
      <section className={cx(TModalStyles, props.className)}>{props.children}</section>
    </>,
    document.body
  )
}

export const ModalHeadingStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
  gap: 20px;

  .close-button {
    align-self: end;
    color: #777;
  }
  .title {
    align-self: start;
  }
`

const TModalHeadingStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
`
export function TModalHeading(props: { children?: React.ReactNode | React.ReactNode[] }) {
  return <div className={TModalHeadingStyles}>{props.children}</div>
}

export default TModal

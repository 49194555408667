import { CoverageMapCardToolComponent } from '../../../../components/CoverageMapCard/CoverageMapCardToolComponent'
import { CoverageMapToolHandler } from '../handlers/CoverageMapToolHandler'
import { NetworkMeterHistoryCard } from '../../components/tool-components/meter/NetworkMeterHistoryCardToolComponent'
import { EmbeddedPlansCarouselToolComponent } from '../../components/tool-components/plans/EmbeddedPlansCarousel'
import { RedirectionToolComponent } from '../../components/tool-components/plans/RedirectionToolComponent'
import { OnMessagePayload } from '../../hooks/useChat'
import { ToolCall } from '../../ServerResponse'
import { MeterToolHandler } from '../handlers/MeterToolHandler'
import { PlanFinderToolHandler } from '../handlers/PlanFinderToolHandler'
import { FreeBotMessage } from '../../types/free-chat.types'
import { UserTextMessage } from '../../types/ChatStep'
import { DefaultSuggestionToolHandler } from '../handlers/DefaultSuggestionToolHandler'
import { InformationalToolHandler } from '../handlers/InformationalToolHandler'

export enum TOOL_NAME {
  COVERAGE_MAP = 'CoverageMap',
  NETWORK_METER = 'NetworkMeter',
  PLAN_FINDER = 'PlanFinder',
  SHOW_SUGGESTIONS = 'ShowSuggestions',
  INFORMATIONAL = 'Informational'
}

export const ToolComponents = {
  // Map
  CoverageMapCardToolComponent,

  // Plans
  EmbeddedPlansCarouselToolComponent,

  // Meter
  NetworkMeterHistory: NetworkMeterHistoryCard,

  // Redirection
  RedirectionToolComponent
}

export type ToolName = 'CoverageMap' | 'PlanFinder' | 'NetworkMeter'

export type ChatInterface<I> = {
  toolCall: ToolCall<I>
  onMessage: (payload: OnMessagePayload) => Promise<void>
  pushHistory: (path: string) => void
  getTimeline: () => (FreeBotMessage | UserTextMessage)[]
}

export interface ToolHandler<I> {
  handle(chatInterface: ChatInterface<I>): void
}

export const ToolMap = {
  [TOOL_NAME.COVERAGE_MAP]: {
    ToolHandler: CoverageMapToolHandler
  },
  [TOOL_NAME.NETWORK_METER]: {
    ToolHandler: MeterToolHandler
  },
  [TOOL_NAME.PLAN_FINDER]: {
    ToolHandler: PlanFinderToolHandler
  },
  [TOOL_NAME.SHOW_SUGGESTIONS]: {
    ToolHandler: DefaultSuggestionToolHandler
  },
  [TOOL_NAME.INFORMATIONAL]: {
    ToolHandler: InformationalToolHandler
  }
}

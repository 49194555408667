import { MainNavigationPaths, RoutePaths } from '../../../../paths'
import { Logger } from '../../../../utils/Logger'
import { ValueOf } from '../../../../utils/types/ValueOf'
import { getLastTool } from '../../hooks/getLastQuestion'
import { ValidatedResults } from '../../ServerResponse'
import { ChatInteraction, MessageType } from '../../types/ChatStep'
import { FreeBotMessage } from '../../types/free-chat.types'
import { Suggestion, SuggestionType } from '../../types/Suggestion'
import { ChatInterface, ToolHandler } from '../const/tool.const'
import { MessageRedirection } from './utils/MessageRedirection'

export type PlanBackendInputParams = {
  filter: 'TopPrepaid' | 'TopUnlimited' | 'InterestingPlans'
  redirectToPlansTabs: boolean
  topPickId: number | null
}
export type ShowPlansBackendInput = {
  action: 'showPlans'
  params: PlanBackendInputParams
}

export type FindPlansBackendInput = {
  action: 'findPlan'
  params: {
    openPlanFinder: boolean
  }
}

export const TopPickFilterToId = {
  TopPrepaid: 0,
  TopUnlimited: 1,
  InterestingPlans: 2
} as const

const topPickSuggestions: Record<ValueOf<typeof TopPickFilterToId>, Suggestion> = {
  [TopPickFilterToId.TopPrepaid]: {
    type: SuggestionType.TEXT,
    text: 'What are the top prepaid plans?',
    value: { parsedAnswer: 'What are the top prepaid plans?', resultStatus: ValidatedResults.VALID }
  },
  [TopPickFilterToId.TopUnlimited]: {
    type: SuggestionType.TEXT,
    text: 'What are the top unlimited plans?',
    value: { parsedAnswer: 'What are the top unlimited plans?', resultStatus: ValidatedResults.VALID }
  },
  [TopPickFilterToId.InterestingPlans]: {
    type: SuggestionType.TEXT,
    text: 'Do you have any interesting plans?',
    value: { parsedAnswer: 'Do you have any interesting plans?', resultStatus: ValidatedResults.VALID }
  }
}

function isShowPlansAction(input: object | undefined): input is ShowPlansBackendInput {
  return !!input && 'action' in input && input.action === 'showPlans'
}

type ShowPlanSuggestionsInput = {
  action: 'showSuggestions'
  params: {
    wasNegativeAnswer: boolean
  }
}

type MenuSelectionInput = {
  action: 'menuSelection'
}

export type PlanFinderToolBackendInput =
  | ShowPlansBackendInput
  | FindPlansBackendInput
  | ShowPlanSuggestionsInput
  | MenuSelectionInput

export class PlanFinderToolHandler implements ToolHandler<PlanFinderToolBackendInput> {
  private messageRedirection: MessageRedirection<PlanFinderToolBackendInput>
  constructor(private chatInterface: ChatInterface<PlanFinderToolBackendInput>) {
    this.messageRedirection = new MessageRedirection(chatInterface)
  }

  onMessage(message: FreeBotMessage) {
    return this.chatInterface.onMessage({
      messageRecord: {
        message
      }
    })
  }

  private showMenu() {
    this.onMessage({
      from: 'bot',
      type: MessageType.STEP,
      question: [{ text: 'Sure! Here are some suggestions...' }],
      suggestions: [
        {
          text: 'Find me the perfect plan',
          type: SuggestionType.TEXT,
          value: {
            parsedAnswer: 'Find me the perfect plan',
            resultStatus: ValidatedResults.VALID
          }
        },
        ...Object.values(topPickSuggestions)
      ],
      interactions: [ChatInteraction.FREE_TEXT]
    })
  }

  private showPlans() {
    const { params } = this.chatInterface.toolCall.input as ShowPlansBackendInput
    if (!params) {
      Logger.error('showPlans called without params')
      return
    }

    if (params.redirectToPlansTabs) {
      const link = MainNavigationPaths.plans + '/' + params.topPickId
      this.messageRedirection.messageRedirection(link, {
        action: 'static',
        params: {
          descriptor: {
            question: {
              question: [{ text: 'Welcome back! What can I help you with?' }],
              type: MessageType.STEP,
              from: 'bot',
              interactions: [ChatInteraction.FREE_TEXT],
              suggestions: []
            },
            redirectingText: 'Got it! Taking you to the Plans Page...'
          }
        }
      })
      return
    }

    this.onMessage({
      from: 'bot',
      type: MessageType.TOOL,
      tool: 'PlanFinder',
      toolComponent: 'EmbeddedPlansCarouselToolComponent',
      backendInput: this.chatInterface.toolCall.input
    })
    this.onMessage({
      from: 'bot',
      type: MessageType.STEP,
      question: [{ text: 'Would you like to browse more plans like this?' }],
      suggestions: [],
      interactions: [ChatInteraction.FREE_TEXT]
    })
  }

  private findPlans() {
    const input = this.chatInterface.toolCall.input as FindPlansBackendInput
    if (input.params?.openPlanFinder) {
      this.messageRedirection.messageRedirection(RoutePaths.chatFinder + '?restart=true', {
        action: 'dynamic-mapped',
        params: {
          behavior: 'plan-finder-status'
        }
      })
    }
  }

  private showSuggestions() {
    const timeline = this.chatInterface.getTimeline()

    const lastTool = getLastTool(timeline)
    const suggestions: Suggestion[] = []

    const backendInput = lastTool?.backendInput

    if (
      lastTool &&
      lastTool.type === MessageType.TOOL &&
      lastTool.tool === 'PlanFinder' &&
      isShowPlansAction(backendInput)
    ) {
      const topPicksExcludingCurrent = (
        Object.keys(topPickSuggestions).map(Number) as unknown as (keyof typeof topPickSuggestions)[]
      )
        .filter((topPickId) => topPickId !== TopPickFilterToId[backendInput.params.filter])
        .map((topPickId) => topPickSuggestions[topPickId])

      suggestions.push(
        {
          type: SuggestionType.TEXT,
          text: 'Show me those plans again',
          value: { parsedAnswer: 'Show me those plans again', resultStatus: ValidatedResults.VALID }
        },
        ...topPicksExcludingCurrent
      )
    } else {
      suggestions.push(...Object.values(topPickSuggestions).slice(0, 2))
    }

    this.onMessage({
      type: MessageType.STEP,
      from: 'bot',
      question: [{ text: 'Sure! Is there anything else I can help with?' }],
      interactions: [ChatInteraction.FREE_TEXT],
      suggestions
    })
  }

  handle(chatInterface: ChatInterface<PlanFinderToolBackendInput>): void {
    const { input } = chatInterface.toolCall

    if (
      input.action === null ||
      input.action === 'menuSelection' ||
      ('filter' in input.params && input.params?.filter === null)
    ) {
      this.showMenu()
      return
    }

    if (input.action === 'showSuggestions') {
      this.showSuggestions()
      return
    }

    if (input.action === 'showPlans') {
      this.showPlans()
      return
    }

    if (input.action === 'findPlan') {
      this.findPlans()
      return
    }

    Logger.error('Plan finder tool call not handled:', chatInterface.toolCall)
  }
}

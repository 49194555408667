import { css } from '@emotion/css'
import { NetworkMeterRecord } from '../../../services/NetworkMeter.service'
import { Measurements } from './Measurements/Measurements'
import { IonButton, IonIcon } from '@ionic/react'
import { SpeedResult } from './SpeedResult/SpeedResult'
import { informationCircleOutline, locationOutline, resize } from 'ionicons/icons'
import MapComponent from '../../Map/Map'
import Marker from '../../Marker'
import React from 'react'
import { MainNavigationPaths } from '../../../paths'
import { useUnsavedAddresses } from '../../CoverageMap/hooks/useUnsavedAddresses'
import { useHistory } from 'react-router'
import { createRoot } from 'react-dom/client'
import { useGeneralStore } from '../../../store'
import { UseCarrier } from '../../../services/Carrier.service'
import { Address, LabelType } from '../../../store/address'

const MeterResultCardStyles = css`
  max-width: 600px;
  background: #fff;

  border-radius: 10px;
  border: 2px solid var(--ion-color-dark);

  margin: 24px 18px;
  padding: 16px;

  .divider {
    height: 1px;
    background: var(--ion-color-light);
    margin: 16px 0;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .section-title {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 18px;
      font-weight: 700;
      color: var(--ion-color-dark);

      > ion-icon {
        width: 24px;
        height: 24px;
        color: var(--ion-color-medium);
      }
    }

    .grid-info {
      display: grid;
      grid-template-columns: 2fr 3fr;
      padding: 0 32px;

      .label {
        color: var(--ion-color-medium);
      }
    }

    .map {
      height: 130px;
      border-radius: 10px;
    }
  }

  .date-time {
    display: flex;
    align-items: end;
    gap: 16px;

    .date {
      font-weight: 900;
      font-size: 22px;
      color: var(--ion-color-dark);
    }
    .time {
      font-size: 16px;
      color: var(--ion-color-medium);
    }
  }
`

const noPadding = css`
  padding: 0 !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
`

const MapControlStyles = css`
  --background: #fff;
  --border-radius: 100%;
  height: 40px;
  width: 40px;
  --padding-start: 8px;
  --padding-end: 8px;
  margin: 12px;
`

interface MeterResultCardProps {
  record: NetworkMeterRecord
  onDismiss: () => void
}

function formatDate(date: Date) {
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit'
  })
}

function formatTime(date: Date) {
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit'
  })
}

function MeterResultCardToAddress(record: NetworkMeterRecord): Address | undefined {
  if (!record.latitude || !record.longitude) {
    return undefined
  }

  return {
    placeId: record.placeId,
    latitude: record.latitude,
    longitude: record.longitude,
    addressName: record.address,
    label: record.address,
    labelType: LabelType.Other
  }
}

const MeterResultCard = ({ record, onDismiss }: MeterResultCardProps) => {
  const date = new Date(record.capturedOn)
  const mapRef = React.useRef<google.maps.Map | null>(null)

  const history = useHistory()
  const { setLocalAddress } = useUnsavedAddresses()
  const [setSelectedAddress] = useGeneralStore((state) => [state.setSelectedAddress])

  const { data: carrier } = UseCarrier.useCarrier(record.carrier)

  const topRightControlDiv = document.createElement('div')
  createRoot(topRightControlDiv).render(
    <IonButton
      onClick={() => {
        const address = MeterResultCardToAddress(record)
        if (!address) {
          return
        }
        setLocalAddress(address)
        setSelectedAddress(address)
        onDismiss()
        history.push(MainNavigationPaths.map)
      }}
      fill='clear'
      className={MapControlStyles}
    >
      <IonIcon icon={resize} slot='icon-only' color='dark' />
    </IonButton>
  )

  React.useEffect(() => {
    if (!mapRef.current || mapRef.current.controls[google.maps.ControlPosition.TOP_RIGHT].getLength()) return
    mapRef.current.controls[google.maps.ControlPosition.TOP_RIGHT].push(topRightControlDiv)
  }, [topRightControlDiv])

  return (
    <div className={MeterResultCardStyles}>
      <div className='date-time'>
        <span className='date'>{formatDate(date)}</span>
        <span className='time'>{formatTime(date)}</span>
      </div>
      <div className='divider' />
      <section className='section'>
        <div className='section-title'>
          <IonIcon src='/assets/icons/signal-meter/meter.svg' />
          Speed
        </div>
        <Measurements
          avgLatency={record.latencyMs}
          uploadSpeed={record.uploadSpeedMbps}
          speed={record.downloadSpeedMbps}
        />
        <SpeedResult downloadSpeed={record.downloadSpeedMbps} className={noPadding} />
      </section>
      <div className='divider' />
      <section className='section'>
        <div className='section-title'>
          <IonIcon icon={locationOutline} />
          Location
        </div>
        {record.address ? (
          <div className='grid-info'>
            <span className='label'>Address</span>
            <span>{record.address}</span>
          </div>
        ) : null}
        <div className='grid-info'>
          <span className='label'>Latitude</span>
          <span>{record.latitude !== null ? record.latitude.toFixed(4) : 'None'}</span>
        </div>
        <div className='grid-info'>
          <span className='label'>Longitude</span>
          <span>{record.longitude !== null ? record.longitude.toFixed(4) : 'None'}</span>
        </div>

        {record.latitude !== null && record.longitude !== null ? (
          <MapComponent
            className='map'
            center={new google.maps.LatLng(record.latitude, record.longitude)}
            zoom={13}
            customizeMap={(map) => {
              mapRef.current = map
            }}
          >
            <Marker
              address={record as { latitude: number; longitude: number }}
              onClick={() => {
                if (mapRef.current && record.latitude !== null) {
                  mapRef.current.panTo(new google.maps.LatLng(record.latitude, record.longitude))
                }
              }}
              active={true}
            />
          </MapComponent>
        ) : null}
      </section>
      <div className='divider' />
      <section className='section'>
        <div className='section-title'>
          <IonIcon icon={informationCircleOutline} />
          Info
        </div>
        {!!record.connectionType && (
          <div className='grid-info'>
            <span className='label'>Network</span>
            <span>{record.connectionType}</span>
          </div>
        )}
        {!!carrier?.name && (
          <div className='grid-info'>
            <span className='label'>Carrier</span>
            <span>{carrier?.name}</span>
          </div>
        )}
        {!!record.device && (
          <div className='grid-info'>
            <span className='label'>Device</span>
            <span>{record.device}</span>
          </div>
        )}
      </section>
    </div>
  )
}

export default MeterResultCard

import { IonButton, IonCol, IonGrid, IonRow, IonSkeletonText } from '@ionic/react'
import ChatStepComponent from '../../pages/llm/components/ChatQuestionComponent'
import { getAddressesSuggestions } from '../../pages/llm/tools/handlers/CoverageMapToolHandler'
import { ChatInteraction, ChatStep, MessageType } from '../../pages/llm/types/ChatStep'
import { Suggestion } from '../../pages/llm/types/Suggestion'
import { useGeneralStore } from '../../store'
import { ChartStyles, CoverageMapCardStyles } from './CoverageMapCard.styles'

export const LoadingMapCard = () => {
  return (
    <div className={CoverageMapCardStyles}>
      <span className='title'>
        <IonSkeletonText animated={true} />
      </span>
      <div className='subtitle'>
        <IonSkeletonText animated={true} />
      </div>
      <div className='coverage-map'>
        <IonSkeletonText animated={true} />
      </div>
      <IonGrid className={ChartStyles}>
        <IonRow>
          <IonCol>
            <IonSkeletonText animated={true} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonSkeletonText animated={true} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className='map-link'>
        <IonSkeletonText animated={true} />
      </div>
    </div>
  )
}

export const AddressNotFoundMapMessage = (props: {
  isActive: boolean
  onSuggestionSelected: (suggestion: Suggestion) => void
}) => {
  const addresses = useGeneralStore((state) => state.addresses)

  const addressNotFoundStep: ChatStep = {
    from: 'bot',
    type: MessageType.STEP,
    question: [
      {
        text: 'Hmm, we couldn’t find the location you entered. Please double-check the address or try entering a nearby city or address.'
      }
    ],
    suggestions: getAddressesSuggestions(addresses),
    interactions: [ChatInteraction.FREE_TEXT]
  }
  return (
    <ChatStepComponent
      status={props.isActive ? 'active' : 'answered'}
      step={addressNotFoundStep}
      onSuggestionSelected={props.onSuggestionSelected}
    />
  )
}

export const ErrorMapCard = ({ onRetry }: { onRetry: () => void }) => {
  return (
    <div className={CoverageMapCardStyles}>
      <div className='error-container'>
        <img src='/assets/images/barry/goji_barry_whoops.svg' alt='error' />
        <span className='error-message'>Oops! Something went wrong</span>
        <IonButton onClick={onRetry} color='primary' shape='round'>
          Try again
        </IonButton>
      </div>
    </div>
  )
}

import { css } from '@emotion/css'
import { MOBILE_BREAKPOINT } from '../../../utils/hooks/useWindowSize'

export const TOP_PICKS_HEIGHT = 208.5
export const leftPadding = 18
export const TopPicksStyles = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .barry-image-background {
    position: absolute;
    top: -20%;
    right: 10%;
    z-index: -1;
  }

  .barry-image-background img {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
    width: 100%;
    transform: scaleX(-1);
  }

  .swiper {
    min-height: ${TOP_PICKS_HEIGHT}px;
    width: 100%;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      width: calc(100% + 2 * ${leftPadding}px);
      transform: translateX(-${leftPadding}px);
    }
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
    &.fit-content {
      width: fit-content;
    }
  }

  .swiper-pagination {
    padding-top: 8px;
    transform: translateX(-${leftPadding / 2}px);
  }

  .top-picks-title {
    font-weight: 900;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 1400px) {
    .barry-image-background img {
      opacity: 0;
    }
  }

  @media screen and (min-width: 768px) {
    .swiper-pagination {
      padding-top: 44px !important;
    }
    .top-picks-title {
      margin-bottom: 32px;
    }
  }
`

export const TopPickBannerStyles = (bgColor: string) => css`
  color: var(--ion-text-color);
  width: 392px;
  min-width: 332px;
  min-height: 181px;

  padding: 18px;
  font-size: 14px;

  ion-router-link {
    text-decoration: underline;
  }

  .banner-title {
    padding-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 900;
    line-height: 22.5px;
  }

  border: 2px solid var(--goji-primary-black);
  border-radius: 10px;
  background-color: ${bgColor};
  max-width: calc(100vw - 36px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .side-image-wrapper {
    height: 100%;
    min-width: 83px;
    position: relative;
  }
  .side-image {
    position: absolute;
    width: 83px;
    height: 108px;
  }

  .heading {
    gap: 12px;
    display: flex;
    justify-content: space-between;
  }

  .heading-text {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 690px;
    height: 238px;
    padding: 24px 18px 32px 18px;
    font-size: 16px;

    .banner-title {
      font-size: 28px;
      line-height: 33.6px;
    }

    .banner-subtitle {
      line-height: 22px;
    }

    .side-image-wrapper {
      min-width: 139px;
    }

    .side-image {
      width: 139px;
      height: 181px;
    }
  }

  .banner-subtitle {
    color: var(--goji-primary-black);
    opacity: 60%;
  }

  .banner-link-wrapper {
    margin-top: 14px;
  }
  .banner-link {
    color: var(--goji-primary-black);
  }
`

import { css } from '@emotion/css'
import { MOBILE_BREAKPOINT } from '../../utils/hooks/useWindowSize'

export const HomePageHeaderStyles = css`
  display: flex;
  justify-content: center;

  .goji-logo {
    width: 65px;
  }
`

export const HomePageTitleStyles = css`
  width: 100%;
  font-size: 22px;
  text-align: left;
  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    text-align: center;
    font-size: 28px;
  }
`

export const HomePageStyles = css`
  --swiper-theme-color: var(--goji-primary-black);
  --offset-bottom: 10px !important;
  transform: translateY(-10px);

  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    background-color: transparent;
    opacity: 1;
    border: 1px solid var(--goji-primary-black);
  }

  .welcome-title {
    margin-top: 0;
    padding-top: 20px;
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 16px;
  }
  .homepage-padding {
    padding: 0 18px;
  }

  .promotion-card-wrapper {
    display: none;
  }

  .ellipse-desktop {
    display: none;
  }

  .homepage-footer {
    display: none;
  }
  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    .homepage-footer {
      display: block;
    }
    .ellipse-mobile {
      display: none;
    }
    .ellipse-desktop {
      display: block;
    }

    .promotion-card-wrapper {
      display: flex;
      justify-content: center;
      & > * {
        width: 926px;
        max-width: 100%;
      }
    }
    .homepage-padding {
      padding: 0;
    }
    .welcome-title {
      padding-top: 48px;
      font-size: 48px;
    }
  }
`

export const CarrierCardStyles = css`
  border: 1px solid var(--ion-color-light);
  border-radius: 10px;
  height: 202px;
  max-width: 180px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px 10px;

  .price {
    font-weight: 600;
  }

  .carrier-logo {
    height: 24px;
  }

  .btn-find-plan {
    max-width: 138px;
    width: 100%;
  }
`

import * as React from 'react'
import { css, cx } from '@emotion/css'
import { IonProgressBar } from '@ionic/react'
import { MOBILE_BREAKPOINT, useIsMobileSize } from '../../utils/hooks/useWindowSize'
import { DeckManager } from './DeckManager'

const LoadingPopupStyles = css`
  display: flex;
  position: absolute;
  pointer-events: none;
  left: 0%;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    top: 10px;
  }
  width: 100%;
  z-index: 5;
  align-items: end;

  &.loading-mobile {
    height: 2px;
    border-radius: 16px;
    overflow: hidden;
  }

  .loading-data-text {
    color: var(--ion-color-dark);
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 125%;
  }
`
const LoadingPopup = (props: { deckManagerRef: React.MutableRefObject<DeckManager | undefined> }) => {
  const isMobile = useIsMobileSize()
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    const evtName = 'changeLoadingState'
    const listener = (loadingState: boolean) => {
      setIsLoading(loadingState)
    }

    props.deckManagerRef.current?.geodataService.on(evtName, listener)
    return () => {
      props.deckManagerRef.current?.geodataService.off(evtName, listener)
    }
  }, [props.deckManagerRef])

  if (!isLoading) {
    return null
  }

  return (
    <article className={cx(LoadingPopupStyles, `${isMobile ? 'loading-mobile' : ''}`)}>
      <IonProgressBar color='tertiary' type='indeterminate' />
    </article>
  )
}

export default LoadingPopup

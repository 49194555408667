import { css } from '@emotion/css'
import { Deal } from '../../pages/deals/deal.service'
import IconTag from '../icons/IconTag'

const DealTagStyles = css`
  background-color: var(--goji-color-accent);

  &,
  .tag-image {
    color: var(--ion-color-dark-contrast);
  }
  font-size: 14px;
  font-weight: 700;

  border-radius: 4px;
  padding: 6.5px 8px;

  width: 212px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;

  .description {
    display: block;
    width: 100%;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export function DealTag(props: { deal: Pick<Deal, 'description'> }) {
  return (
    <div className={DealTagStyles}>
      <IconTag />
      <span className='description'>{props.deal.description}</span>
    </div>
  )
}

import API from '../../api'
import { updateChatCache } from '../../pages/llm/hooks/useChatStorage'
import { IChatFlow } from '../../pages/llm/types/ChatStep'
import { PredefinedChatStorage } from '../../pages/llm/types/ChatStorageSource'
import DefaultSourceSets from '../../pages/llm/utils/source-sets'

export class ChatSourceService {
  constructor(public sourceSets = DefaultSourceSets) {}
  async uploadFromLocalStorageToApi() {
    const promises = this.sourceSets.map(async (sourceSet) => {
      const localData = await sourceSet.localSource.getStorage()

      if (!localData) {
        return
      }

      await sourceSet.remoteSource.setStorage(localData)
      updateChatCache(localData)
    })
    await Promise.all(promises)
  }

  async removeLocalData() {
    const promises = this.sourceSets.map(async (sourceSet) => {
      await sourceSet.localSource.setStorage(undefined)
    })
    await Promise.all(promises)
  }
}

export const chatSourceService = new ChatSourceService()

export class ChatService {
  async uploadChatStorage<T extends IChatFlow>(chatStorage: PredefinedChatStorage<T>) {
    await API.put('/profile/chat-state', chatStorage)
  }
}

const chatService = new ChatService()

export default chatService

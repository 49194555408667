import { ChatTimelineProps } from '../components/ChatTimeline'
import { BotToolMessage, ChatInteraction, ChatStep, MessageType } from '../types/ChatStep'

export function getLastTool(chatTimeline: ChatTimelineProps['timeline']): BotToolMessage | undefined {
  for (let i = chatTimeline.length - 1; i >= 0; i--) {
    const record = chatTimeline[i]
    if (record.type === MessageType.TOOL) {
      return record
    }
  }
}

export function getLastQuestionOrTool(chatTimeline: ChatTimelineProps['timeline']): ChatStep | undefined {
  for (let i = chatTimeline.length - 1; i >= 0; i--) {
    const record = chatTimeline[i]
    if (record.type === MessageType.TOOL) {
      return {
        from: 'bot',
        type: MessageType.STEP,
        interactions: [ChatInteraction.FREE_TEXT],
        suggestions: [],
        question: []
      }
    }
    if (record.type === MessageType.STEP) {
      return record
    }
  }
}

import { cx } from '@emotion/css'
import { IonCard, IonCardContent, IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import * as React from 'react'
import { UseCarrier } from '../../services/Carrier.service'
import CarrierDisplay from '../CarrierDisplay/CarrierDisplay'
import PlanInfo from '../Plan/PlanInfo'
import { PlanResultCardStyles } from './PlanResultCard.styles'
import { usdIntegerCurrencyFormatter } from '../../utils/usdFormatter'
import { useBoundStore } from '../../store'
import { useHistory } from 'react-router'
import { MainNavigationPaths } from '../../paths'
import { Plan } from '../../services/planService/Plan'
import { NetworkPerformance } from './NetworkPerformance'
import { FavoriteButton } from '../Header/SummaryHeader'
import useAuthStore from '../../store/auth'
import { DealTag } from './DealTag'
import { useFlag } from '../../utils/feature-flags/useFlag'
import { FeatureFlag } from '../../utils/FeatureFlags'

export interface PlanResultCardProps {
  ref?: React.Ref<HTMLIonCardElement>
  plan: Plan
  afterLogo?: React.ReactNode
  gridEnd?: React.ReactNode
  className?: string
  hidePerformance?: boolean
  hideFavoriteButton?: boolean
  alwaysExpanded?: boolean
  isSummary?: boolean
  isCarrierUnsupported?: boolean
  routerLink?: string
  onPlanClick?: (plan: Plan) => void
}
const PlanResultCard: React.FC<PlanResultCardProps> = ({
  ref,
  plan,
  // TODO: where should this value come from?
  gridEnd,
  className,
  hidePerformance,
  hideFavoriteButton,
  alwaysExpanded,
  isSummary: showIncludesSection,
  isCarrierUnsupported,
  onPlanClick
}) => {
  const carrier = UseCarrier.useCarrier(plan.carrierId).data
  const dealsFlag = useFlag(FeatureFlag.DEALS)

  const onClickCard = () => {
    onPlanClick?.(plan)
  }

  const history = useHistory()
  const [favoritePlanIds, addFavoritePlanId, removeFavoritePlanId] = useBoundStore((state) => [
    state.favoritePlanIds,
    state.addFavoritePlanId,
    state.removeFavoritePlanId
  ])
  const isFavorite = favoritePlanIds.some((id) => id === plan.planId)
  const currentUser = useAuthStore((state) => state.currentUser)

  const onFavoriteClick = () => {
    if (!currentUser) {
      history.push(MainNavigationPaths.signIn)
      return
    }
    if (!isFavorite) {
      addFavoritePlanId(plan.planId)
    } else {
      removeFavoritePlanId(plan.planId)
    }
  }

  const getPlanName = () => {
    if (plan.shortPlanName.includes(plan.carrierName)) {
      return plan.shortPlanName
    }
    return `${plan.carrierName} ${plan.shortPlanName}`
  }

  const deals = plan.deals.filter((deal) => deal.planSpecific)

  return (
    <IonCard
      ref={ref}
      onClick={onClickCard}
      className={cx('ion-margin-vertical no-padding no-margin', PlanResultCardStyles, className)}
    >
      <IonCardContent className='plan-result-card-content'>
        <IonGrid className='plan-result-card-grid'>
          <IonRow className='ion-justify-content-between ion-align-items-center'>
            <IonCol className='ion-text-left extra-margin' size='9' sizeXs='7'>
              {carrier ? (
                <IonRow>
                  <CarrierDisplay carrier={carrier} smaller={true} />
                </IonRow>
              ) : (
                <span />
              )}
              <IonText color='dark' className='short-plan-name'>
                {getPlanName()}
              </IonText>
            </IonCol>
            <IonCol className='ion-text-right' size='3' sizeXs='5'>
              <h1 className='plan-price'>{usdIntegerCurrencyFormatter.format(plan.costPerLine)}</h1>
              <h6>/mo. per line</h6>
            </IonCol>
          </IonRow>

          {hidePerformance === true ? null : (
            <>
              <NetworkPerformance
                networkPerformance4G={plan.networkPerformance4G}
                networkPerformance5G={plan.networkPerformance5G}
                noCoverage={plan.noCoverage}
                unsupported={isCarrierUnsupported}
              />
              <div className='divider' />
            </>
          )}
          <PlanInfo plan={plan} alwaysExpanded={alwaysExpanded} isSummary={showIncludesSection} />

          {hideFavoriteButton ? null : (
            <IonRow className='favorite-button-row' style={{ display: `${showIncludesSection ? 'none' : 'flex'}` }}>
              {deals.length && dealsFlag ? <DealTag deal={deals[0]} /> : <div />}
              <FavoriteButton
                isFavorite={isFavorite}
                onClick={(e) => {
                  e.stopPropagation()
                  onFavoriteClick()
                }}
                className='favorite-button'
              />
            </IonRow>
          )}

          {gridEnd}
        </IonGrid>
      </IonCardContent>
    </IonCard>
  )
}

export default PlanResultCard

import { css, cx } from '@emotion/css'
import { IonAccordionGroup } from '@ionic/react'
import React from 'react'
import IconTag from '../../components/icons/IconTag'
import { useFlag } from '../../utils/feature-flags/useFlag'
import { FeatureFlag } from '../../utils/FeatureFlags'
import { MOBILE_BREAKPOINT_MAX } from '../../utils/hooks/useWindowSize'
import { ResetMargin } from '../../utils/styles/MarginUtils.styles'
import { PlanDeal } from '../deals/deal.service'
import { DealsRedirectionModal } from '../deals/DealsRedirectionModal'
import DealsAccordion from './DealsAccordion'

const SummaryDealsStyles = css`
  @media screen and (max-width: ${MOBILE_BREAKPOINT_MAX}px) {
    padding: 0 18px;
  }
`

const PromotionTitleStyles = css`
  display: flex;
  align-items: center;
  gap: 8px;

  .icon-tag {
    color: var(--goji-color-accent);
    width: 30px;
    height: 30px;
  }
`

function getDealsSplit(deals: PlanDeal[]) {
  const planDeals: PlanDeal[] = []
  const carrierDeals: PlanDeal[] = []

  for (const deal of deals) {
    if (deal.planSpecific) {
      planDeals.push(deal)
    } else {
      carrierDeals.push(deal)
    }
  }

  return { planDeals, carrierDeals }
}

function SummaryDeals(props: { deals: PlanDeal[] }) {
  const [selectedDeal, setSelectedDeal] = React.useState<PlanDeal | null>(null)

  const dealsFlag = useFlag(FeatureFlag.DEALS)
  if (!dealsFlag || !props.deals.length) {
    return null
  }

  const { planDeals, carrierDeals } = getDealsSplit(props.deals)

  return (
    <section className={SummaryDealsStyles}>
      <DealsRedirectionModal
        onDidDismiss={() => {
          setSelectedDeal(null)
        }}
        selectedDeal={selectedDeal}
      />
      <div className='summary-deals-heading'>
        <h3 className={cx(ResetMargin, PromotionTitleStyles)}>
          <IconTag className='icon-tag' />
          Eligible Promotions
        </h3>
      </div>

      <IonAccordionGroup multiple value={['plan-deals', 'carrier-deals']}>
        <DealsAccordion
          value='plan-deals'
          onClickDeal={setSelectedDeal}
          deals={planDeals}
          title={`For this plan (${planDeals.length})`}
        />
        <DealsAccordion
          value='carrier-deals'
          onClickDeal={setSelectedDeal}
          deals={carrierDeals}
          title={`For all plans (${carrierDeals.length})`}
        />
      </IonAccordionGroup>
    </section>
  )
}

export default SummaryDeals

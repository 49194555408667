import { css } from '@emotion/css'
import { IonButton } from '@ionic/react'
import { LazyPlansCarousel } from '../../../../../components/PlanResult/LazyPlansCarousel'
import { UsePlan } from '../../../../../services/planService/Plan.service'
import { Logger } from '../../../../../utils/Logger'
import { ShowPlansBackendInput } from '../../../tools/handlers/PlanFinderToolHandler'
import Message from '../../Message'

const EmbeddedPlansCarouselStyles = css`
  --swiper-pagination-color: var(--goji-primary-black);
  --bullets-padding-top: 16px;
  --plan-card-height: 486px;
  --plan-card-width: 392px;
`

function TopPickMessage(props: { topPickId: number }) {
  const topPicks = UsePlan.useTopPicks()
  const topPickName = topPicks.isFetched
    ? topPicks.data?.find((topPick) => topPick.id === props.topPickId)?.toolbarText ?? undefined
    : '...'
  if (topPickName === undefined) {
    return (
      <Message from='bot'>
        Failed to fetch top picks <IonButton onClick={() => topPicks.refetch()}>Try again</IonButton>
      </Message>
    )
  }

  return <Message from='bot'>Here are three of our {topPickName.toLowerCase()} plans:</Message>
}

export function EmbeddedPlansCarouselToolComponent(props: { backendInput: ShowPlansBackendInput }) {
  const topPickId = props.backendInput.params?.topPickId ?? null
  const plans = UsePlan.useTopPicksPlans({ topPicksIds: topPickId !== null ? [topPickId] : [] })

  if (props.backendInput.params === null || topPickId === null) {
    Logger.error('params are null')
    return null
  }

  if (!plans.data && plans.error) {
    return (
      <div>
        Error while fetching plans <IonButton onClick={() => plans.refetch()}>Try again</IonButton>
      </div>
    )
  }

  return (
    <>
      <TopPickMessage topPickId={topPickId} />
      <div className={EmbeddedPlansCarouselStyles}>
        <LazyPlansCarousel loading={plans.isLoading || plans.isIdle} plans={(plans.data?.[0] ?? []).slice(0, 3)} />
      </div>
    </>
  )
}

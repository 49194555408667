import { RedirectionToolComponentProps } from '../../../components/tool-components/plans/RedirectionToolComponent'
import { MessageType } from '../../../types/ChatStep'
import { ChatInterface } from '../../const/tool.const'

export const FreshRedirectionSymbol = Symbol('FreshRedirection')
export class MessageRedirection<T> {
  constructor(private chatInterface: ChatInterface<T>) {}

  messageRedirection(toLink: string, msgParams: RedirectionToolComponentProps['backendInput']) {
    msgParams.params[FreshRedirectionSymbol] = true

    this.chatInterface.onMessage({
      messageRecord: {
        message: {
          from: 'bot',
          type: MessageType.TOOL,
          tool: 'PlanFinder',
          toolComponent: 'RedirectionToolComponent',
          backendInput: msgParams
        }
      }
    })

    window.setTimeout(() => {
      this.chatInterface.pushHistory(toLink)
      delete msgParams.params[FreshRedirectionSymbol]
    }, 3000)
  }
}

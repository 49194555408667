import { Capacitor, PluginListenerHandle } from '@capacitor/core'
import {
  registerSignalStrengthPlugin,
  SignalStrengthChangedEvent,
  SignalStrengthPluginMethods
} from '../plugin/SignalStrengthPlugin'
import { Logger } from '../utils/Logger'

const isNativeAndroid = Capacitor.getPlatform() === 'android'
const nativeAndroidGuard = (cb: Function) => {
  if (isNativeAndroid) {
    cb()
  } else {
    Logger.warn('Cannot call function: not on native android')
  }
}

let plugin: null | SignalStrengthPluginMethods = null
if (isNativeAndroid) {
  plugin = registerSignalStrengthPlugin()
}

export const SignalStrengthService = {
  isListening: false,
  plugin,

  async shouldShowPermissionRationale() {
    if (!this.plugin) {
      Logger.warn('plugin is not defined')
    }
    const result = await this.plugin?.shouldShowPermissionRationale()
    return result?.shouldShowRationale
  },
  async hasPermissions() {
    if (!this.plugin) {
      Logger.warn('plugin is not defined')
    }
    const result = await this.plugin?.checkSignalStrengthPermissions()
    return !!result?.isGranted
  },

  listener: null as PluginListenerHandle | null,
  init(onStrengthChanged: (evt: SignalStrengthChangedEvent) => void) {
    if (!SignalStrengthService.plugin) {
      return
    }

    if (this.listener) {
      this.listener.remove()
    }

    this.listener = SignalStrengthService.plugin.addListener('signalStrengthChanged', onStrengthChanged)
  },

  async goToSettings() {
    return this.plugin?.goToSettings()
  },
  requestPermissions() {
    if (!SignalStrengthService.plugin) {
      return Promise.reject('SignalStrengthService: cannot start: plugin not registered')
    }

    return SignalStrengthService.plugin.requestSignalStrengthPermissions().then(() => {
      /*
      M2CatalystSdkPlugin.requestM2Permissions()
        .then(() => {
          Logger.debug('m2 permissions granted')
        })
        .catch((e) => {
          Logger.error('m2 permissions error ', e)
        })
      */
    })
  },
  listenForChanges() {
    nativeAndroidGuard(() => {
      if (!SignalStrengthService.plugin) {
        Logger.warn('SignalStrengthService: cannot start: plugin not registered')
        return
      }

      SignalStrengthService.isListening = true
      SignalStrengthService.plugin?.listenForSignalStrengthChanges()
    })
  },
  stop() {
    nativeAndroidGuard(() => {
      SignalStrengthService.isListening = false
      SignalStrengthService.plugin?.unlistenForSignalStrengthChanges()
    })
  },
  destroy() {
    this.listener?.remove()
    this.listener = null
    SignalStrengthService.stop()
  }
}

import * as React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Route, Switch } from 'react-router-dom'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { enableMapSet } from 'immer'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/general.css'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './queryClient'
import BackendSyncLoader from './components/BackendSyncLoader/BackendSyncLoader'
import { MainNavigation } from './pages/main-navigation/MainNavigation'
import { withSuspense } from './pages/main-navigation/withSuspense'
import AppUrlListener from './components/AppUrlListener'
import { ProfilePaths, RoutePaths } from './paths'
import PlanSwipePage from './pages/llm/PlanSwipePage'
import PlanSwipeTransitionPage from './pages/llm/PlanSwipeTransitionPage'
import InstallPrompt from './components/InstallPrompt'
import ChatbotPage from './pages/llm/ChatbotPage'
import ChatMenuPage from './pages/llm/ChatMenuPage'
import { ChatEntrypoint } from './pages/llm/ChatEntrypoint'
import { Auth, Hub } from 'aws-amplify'
import { MapsWrapper } from './components/BackendSyncLoader/MapsWrapper'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary'
import AmplifyComponent from './components/Amplify/Amplify'
import { ComparePage } from './pages/compare/ComparePage'
import { FreeChatPage } from './pages/llm/free/FreeChatPage'
import defaultHistory from './router-history'

enableMapSet()

const ConfirmationCodePage = withSuspense(React.lazy(() => import('./pages/sign-in/ConfirmationCode')))
const ForgotPasswordPage = withSuspense(React.lazy(() => import('./pages/sign-in/ForgotPasswordPage')))
const ResetPasswordPage = withSuspense(React.lazy(() => import('./pages/sign-in/ResetPasswordPage')))
const AccountCreated = withSuspense(React.lazy(() => import('./pages/sign-in/AccountCreated/AccountCreated')))
const QRCodeReadPage = withSuspense(React.lazy(() => import('./pages/qrcode-read/QRCodeReadPage')))
const AddressBookPage = withSuspense(React.lazy(() => import('./pages/address-book/AddressBookPage')))
const OnboardingMobileCarousel = withSuspense(
  React.lazy(() => import('./components/OnboardingCarousel/OnboardingMobileCarousel'))
)
const PlanPreferences = withSuspense(React.lazy(() => import('./pages/profile/PlanPreferences')))
const SavedPlans = withSuspense(React.lazy(() => import('./pages/profile/SavedPlans')))
const AboutPage = withSuspense(React.lazy(() => import('./pages/profile/AboutPage')))

// setup ionic
setupIonicReact()

function RouteDefinitions() {
  return (
    <IonReactRouter history={defaultHistory}>
      <AmplifyComponent hub={Hub} auth={Auth} />
      <AppUrlListener />
      <IonRouterOutlet>
        <ErrorBoundary>
          <MapsWrapper>
            <BackendSyncLoader useFeatureFlags>
              <Switch>
                <Route exact path={RoutePaths.forgotPassword} component={ForgotPasswordPage} />
                <Route exact path={RoutePaths.resetPassword + '/:email'} component={ResetPasswordPage} />
                <Route exact path={RoutePaths.confirmationCode + '/:username'} component={ConfirmationCodePage} />
                <Route exact path={RoutePaths.accountCreated} component={AccountCreated} />
                <Route exact path={RoutePaths.qrcodeRedirect} component={QRCodeReadPage} />
                <Route exact path={RoutePaths.onboarding} component={OnboardingMobileCarousel} />
                <Route exact path={RoutePaths.addressBook} component={AddressBookPage} />
                <Route exact path={RoutePaths.chatbot} component={ChatEntrypoint} />
                <Route exact path={RoutePaths.chatFinder} component={ChatbotPage} />
                <Route exact path={RoutePaths.chatMenu} component={ChatMenuPage} />
                <Route exact path={RoutePaths.chatFree} component={FreeChatPage} />
                <Route exact path={RoutePaths.planSwipe} component={PlanSwipePage} />
                <Route exact path={RoutePaths.planSwipeTransition} component={PlanSwipeTransitionPage} />
                <Route exact path={RoutePaths.compare + '/:plan_ids'} component={ComparePage} />
                {/* Profile routes */}
                <Route exact path={ProfilePaths.planPreferences} component={PlanPreferences} />
                <Route exact path={ProfilePaths.savedPlans} component={SavedPlans} />
                <Route exact path={ProfilePaths.about} component={AboutPage} />
                {/* Main navigation routes */}
                <Route path={RoutePaths.root} component={MainNavigation} />
              </Switch>
            </BackendSyncLoader>
          </MapsWrapper>
        </ErrorBoundary>
      </IonRouterOutlet>
    </IonReactRouter>
  )
}

const App: React.FC = () => {
  return (
    <IonApp>
      <HelmetProvider>
        <Helmet>
          <title>Goji</title>
        </Helmet>
        <InstallPrompt />
        <QueryClientProvider client={queryClient}>
          <RouteDefinitions />
        </QueryClientProvider>
      </HelmetProvider>
    </IonApp>
  )
}

export default App

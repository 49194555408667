import { Logger } from '../../../../utils/Logger'
import { ChatFlowSelector } from '../../ChatFlow'
import { ChatInteraction, MessageType } from '../../types/ChatStep'
import { FreeBotMessage } from '../../types/free-chat.types'
import { ChatInterface, ToolHandler } from '../const/tool.const'

type InformationalToolBackendInput = {
  action: 'notDetermined' | 'whatIsGoji' | 'shopPhone' | 'askingHelp' | 'thingsToDo' | 'getStarted'
  message: string
}

export class InformationalToolHandler implements ToolHandler<InformationalToolBackendInput> {
  constructor(private chatInterface: ChatInterface<InformationalToolBackendInput>) {}

  private onMessage(message: FreeBotMessage) {
    return this.chatInterface.onMessage({
      messageRecord: {
        message
      }
    })
  }

  private handleUndefinedMessage() {
    Logger.error('Message is undefined', this.chatInterface.toolCall)
    this.onMessage({
      from: 'bot',
      question: [{ text: 'Sorry, this message could not be processed. Please try again later.' }],
      type: MessageType.STEP,
      suggestions: ChatFlowSelector.question__start_plan_finder_free.suggestions,
      interactions: [ChatInteraction.FREE_TEXT]
    })
  }

  private handleAction() {
    const input = this.chatInterface.toolCall.input
    if (input.action === 'askingHelp' || input.action === 'thingsToDo' || input.action === 'getStarted') {
      this.onMessage({
        from: 'bot',
        type: MessageType.STEP,
        interactions: [ChatInteraction.FREE_TEXT],
        question: [{ text: input.message }],
        suggestions: ChatFlowSelector.question__start_plan_finder_free.suggestions
      })
    } else {
      this.onMessage({
        from: 'bot',
        type: MessageType.TEXT,
        text: this.chatInterface.toolCall.input.message
      })
    }
  }

  handle(): void {
    if (this.chatInterface.toolCall.input.message) {
      this.handleAction()
    } else {
      this.handleUndefinedMessage()
    }
  }
}

import { IChatFlow } from '../types/ChatStep'
import { ChatStorageSource, PredefinedChatStorage } from '../types/ChatStorageSource'
import { FreeChat, getFreeChatApiSource, getPlanChatApiSource } from './chatApiSource'
import { createLocalStorageSource } from './chatStorageSource'

export class SourceSet<T> {
  constructor(public localSource: ChatStorageSource<T>, public remoteSource: ChatStorageSource<T>) {}
}
const CHAT_LS_KEY = 'chatStorage'
export const getPlanChatLocalStorageSource = <T extends IChatFlow>() =>
  createLocalStorageSource<PredefinedChatStorage<T>>(CHAT_LS_KEY)

const getFreeChatLocalStorageSource = () => createLocalStorageSource<FreeChat>('freeChatStorage')

export const PlanChatSourceSet = new SourceSet(getPlanChatLocalStorageSource(), getPlanChatApiSource())

export const FreeChatSourceSet = new SourceSet<FreeChat>(getFreeChatLocalStorageSource(), getFreeChatApiSource())

const DefaultSourceSets = [PlanChatSourceSet]

export default DefaultSourceSets

import { css } from '@emotion/css'
import { IonItem, IonLabel, IonList, IonListHeader, IonSkeletonText, IonThumbnail } from '@ionic/react'
import { Separator } from '../Separator/Separator'

const PlanResultCardSkeletonV2Styles = css`
  padding: 16px;
  border: 2px solid var(--goji-primary-black);
  border-radius: 10px;
  width: var(--plan-card-width);
  height: var(--plan-card-height);

  .separator-wrapper {
    padding-top: 12px;
  }
`
export function PlanResultCardSkeletonV2() {
  return (
    <div className={PlanResultCardSkeletonV2Styles}>
      <div className='header ion-margin-bottom'>
        <div>
          <div style={{ padding: '5px 0' }}>
            <IonSkeletonText
              className='logo'
              animated={true}
              style={{ width: '80px', height: '28px', margin: '0' }}
            ></IonSkeletonText>
          </div>
          <IonSkeletonText
            className='title'
            animated={true}
            style={{ width: '160px', height: '48px' }}
          ></IonSkeletonText>
        </div>
      </div>
      <section>
        <IonSkeletonText className='title' animated={true} style={{ width: '120px', height: '24px' }}></IonSkeletonText>
        <IonSkeletonText className='title' animated={true} style={{ width: '120px', height: '24px' }}></IonSkeletonText>
      </section>
      <div className='ion-padding-horizontal separator-wrapper' style={{ position: 'relative' }}>
        <Separator color='var(--ion-color-light)' size={1} />
      </div>
      <div className='ion-padding-top'>
        <IonSkeletonText className='content-skeleton' animated={true} style={{ width: '100%', height: '82px' }} />
      </div>
    </div>
  )
}

export function PlanResultCardSkeleton({ className }: { className?: string }) {
  return (
    <IonList className={className}>
      <IonListHeader>
        <IonSkeletonText animated={true} style={{ width: '80px' }}></IonSkeletonText>
      </IonListHeader>
      <IonItem lines='none'>
        <IonThumbnail slot='start'>
          <IonSkeletonText animated={true}></IonSkeletonText>
        </IonThumbnail>
        <IonLabel>
          <h3>
            <IonSkeletonText animated={true} style={{ width: '80%' }}></IonSkeletonText>
          </h3>
          <p>
            <IonSkeletonText animated={true} style={{ width: '60%' }}></IonSkeletonText>
          </p>
          <p>
            <IonSkeletonText animated={true} style={{ width: '30%' }}></IonSkeletonText>
          </p>
        </IonLabel>
      </IonItem>
    </IonList>
  )
}

import { Plan } from '../../services/planService/Plan'

export const PlanNotFound: Plan = {
  carrierId: '',
  carrierName: '',
  totogiCarrierName: '',
  video_streaming_quality: '',
  priceMultiLine: '',
  priceCurrency: '',
  planLink: '',
  data: undefined,
  telcoType: 'MNO',
  sms: undefined,
  voice: undefined,
  price: -1,
  networkPerformance4G: 0,
  addons: [],
  deals: [],
  planId: 'plan-not-found',
  planName: 'Can’t find my plan in the list',
  shortPlanName: 'Can’t find my plan in the list',
  costPerLine: -1
}

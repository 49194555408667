import { css, cx } from '@emotion/css'
import { IonRouterLink } from '@ionic/react'
import CarrierDisplay, { smallImageStyles } from '../../../components/CarrierDisplay/CarrierDisplay'
import { LocationsList } from '../../../components/LocationsList/LocationsList'
import { MainNavigationPaths } from '../../../paths'
import { Carrier } from '../../../store/carrierSelector'
import { Address } from '../../../store/address'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'
import { JustForYouCardFrame } from './JustForYouCard'
import { useIsMobileSize } from '../../../utils/hooks/useWindowSize'

const BestCarrierCardStyles = css`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .card-left {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 16px;
    padding-right: 15%;
    max-width: 50%;
  }

  .locations-list-box {
    display: flex;
    flex: 1 1 auto;
    max-width: 50%;
    background-color: var(--goji-color-secondary-blue);
    border-radius: 8px;
    padding: 16px 8px;
    flex-direction: column;

    .more-text {
      color: var(--goji-color-description);
      font-size: 14px;
      padding-top: 8px;
      padding-left: 16px;
    }

    .hidden {
      display: none;
    }
  }

  .mobile-locations-list-box {
    max-width: 100%;
  }

  .carrier-logo {
    display: flex;
    justify-content: flex-start;
  }

  .card-title {
    font-size: 18px;
  }

  .carrier-display {
    width: fit-content;
  }

  .description {
    color: var(--goji-color-description);
    line-height: 22px;
    word-wrap: normal;
  }

  .view-map {
    color: var(--ion-text-color);
    text-decoration: underline;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 600;
  }

  .best-carrier-card-mobile-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
`

function BestCarrierCardDescription(props: { carrier: Carrier | undefined }) {
  return (
    <>
      {props.carrier ? (
        <CarrierDisplay carrier={props.carrier} className='carrier-logo' />
      ) : (
        <div className={smallImageStyles} />
      )}
      <div className='description'>
        Based on your locations,&nbsp;{props.carrier ? props.carrier.name : '...'}
        <br />
        provides the best 5G network performance.
      </div>
    </>
  )
}

interface BestCarrierCardProps {
  carrier?: Carrier
  isLoading?: boolean
  addresses: Address[]
}
export function BestCarrierCard(props: BestCarrierCardProps) {
  const isMobile = useIsMobileSize()

  return (
    <JustForYouCardFrame className={BestCarrierCardStyles}>
      {isMobile ? (
        <section className='best-carrier-card-mobile-wrapper'>
          <h3 className={cx(ResetMargin, 'card-title')}>Best 5G carrier for you</h3>
          <BestCarrierCardDescription carrier={props.carrier} />
          <section className='locations-list-box mobile-locations-list-box'>
            <LocationsList limit={3} addresses={props.addresses} />
          </section>
          <IonRouterLink className='view-map' routerLink={MainNavigationPaths.map}>
            View map
          </IonRouterLink>
        </section>
      ) : (
        <>
          <div className='card-left'>
            <h3 className={cx(ResetMargin, 'card-title')}>Best 5G carrier for you</h3>
            <BestCarrierCardDescription carrier={props.carrier} />
            <IonRouterLink className='view-map' routerLink={MainNavigationPaths.map}>
              View map
            </IonRouterLink>
          </div>

          <section className='locations-list-box'>
            <LocationsList limit={3} addresses={props.addresses} />
          </section>
        </>
      )}
    </JustForYouCardFrame>
  )
}

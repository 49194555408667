import { css, cx } from '@emotion/css'
import { IonAccordion, IonButton, IonItem } from '@ionic/react'
import React from 'react'
import { trackAmplitudeEvent } from '../../utils/amplitude'
import { ResetMargin } from '../../utils/styles/MarginUtils.styles'
import { PlanDeal } from '../deals/deal.service'
import DealExpirationText from '../deals/DealExpirationText'
import { DealModalSingleton } from '../deals/DealModalSingleton'

const DealsListStyles = css`
  list-style: none;
`
const DealItemStyles = css`
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 16px;
  background-color: var(--ion-color-danger-background-shade);

  .description {
    margin-bottom: 8px;
  }

  .button-link {
    color: var(--ion-text-color);
    font-weight: 600;
    font-size: 14px;
  }
`
const AccordionHeadingStyles = css`
  margin-top: 16px;
  &::part(native) {
    padding: 0;
  }
  h4 {
    font-weight: 700;
  }
`

const ShowMoreButtonWrapperStyles = css`
  display: flex;
  justify-content: center;

  .show-more {
    width: 100%;
    max-width: 368px;
    height: 52px;
  }
`

function DealsAccordion(props: {
  onClickDeal: (deal: PlanDeal) => void
  deals: PlanDeal[]
  title: string
  value: string
}) {
  const [showItems, setShowItems] = React.useState(2)

  const deals = props.deals.slice(0, showItems)

  if (!props.deals.length) {
    return null
  }
  return (
    <IonAccordion value={props.value}>
      <IonItem className={AccordionHeadingStyles} slot='header'>
        <h4 className={cx(ResetMargin)}>{props.title}</h4>
      </IonItem>
      <div slot='content'>
        <ul className={DealsListStyles}>
          {deals.map((deal) => {
            return (
              <li className={DealItemStyles} key={deal.dealId}>
                <p className={cx(ResetMargin, 'description')}>{deal.description}</p>

                <div>
                  <DealExpirationText dealExpiration={deal.expirationDate} />
                </div>

                <a
                  href={deal.url}
                  target='_blank'
                  rel='noreferrer'
                  className='button-link'
                  onClick={(evt) => {
                    if (!DealModalSingleton.hasSeenModal) {
                      evt.preventDefault()
                      DealModalSingleton.hasSeenModal = true
                      props.onClickDeal(deal)
                    } else {
                      trackAmplitudeEvent('Deals - navigated to carrier website', { deal, from: 'plan summary page' })
                    }
                  }}
                >
                  View promotion details
                </a>
              </li>
            )
          })}
        </ul>
        {showItems < props.deals.length ? (
          <div className={ShowMoreButtonWrapperStyles}>
            <IonButton
              expand='full'
              className='show-more'
              onClick={() => setShowItems(showItems + 3)}
              fill='outline'
              shape='round'
            >
              SHOW MORE
            </IonButton>
          </div>
        ) : null}
      </div>
    </IonAccordion>
  )
}

export default DealsAccordion

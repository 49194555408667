import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { Logger } from '../../../utils/Logger'
import { ChatFlow, PredefinedChatMessageRecord } from '../ChatFlow'
import { IChatFlow, MessageType } from '../types/ChatStep'
import { chatAnswersToPreference } from '../utils/chatToRecommendedPayload'
import useAuthStore from '../../../store/auth'
import { PredefinedChatStorage, PredefinedChatStorageSource } from '../types/ChatStorageSource'
import { queryClient } from '../../../queryClient'
import { getChatStatus } from './getChatStatus'
import { OnMessagePayload } from './useChat'
import { PlanChatSourceSet } from '../utils/source-sets'

const CHAT_STORAGE_QUERY_KEY = ['chatStorage']
export function updateChatCache<T extends IChatFlow>(newState: PredefinedChatStorage<T> | undefined) {
  if (newState) {
    queryClient.setQueryData(CHAT_STORAGE_QUERY_KEY, applyBackwardsCompatibility(newState, ChatFlow as any))
  } else if (process.env.NODE_ENV !== 'test') {
    Logger.warn('updateStorageQueryData called with undefined newState')
  }
}

export function getChatCache<T extends IChatFlow>() {
  return queryClient.getQueryData<PredefinedChatStorage<T>>(CHAT_STORAGE_QUERY_KEY)
}

function getInitialMessages<T extends IChatFlow>(chatFlow: T) {
  const messages: (keyof T)[] = []
  let currentKey: keyof T = 'entrypoint'

  while (currentKey) {
    const currentStep = chatFlow[currentKey]

    if (!currentStep) {
      break
    }

    if (currentStep.type === MessageType.STEP) {
      messages.push(currentKey)
      break
    }

    if (!currentStep.nextMessageKey) {
      break
    }

    messages.push(currentKey)
    currentKey = currentStep.nextMessageKey
  }

  return messages
}

function getInitialTimeline<T extends IChatFlow>(chatFlow: T) {
  return getInitialMessages(chatFlow).map((key) => ({ key }))
}

function useInitialTimeline<T extends IChatFlow>(chatFlow: T) {
  const initialTimeline = React.useMemo(() => {
    return getInitialTimeline(chatFlow)
  }, [chatFlow])

  return initialTimeline
}

export function useIsInitialTimeline() {
  const { chatStorage } = useAutoChatStorage()
  const initialTimeline = useInitialTimeline(ChatFlow)

  if (!chatStorage.data) {
    return false
  }

  return chatStorage.data.chatTimeline.every((record, index) => {
    if (!('key' in record)) {
      return false
    }
    return record.key === initialTimeline[index].key
  })
}

function applyBackwardsCompatibility<T extends IChatFlow>(chatStorage: PredefinedChatStorage<T>, chatFlow: T) {
  if ((chatStorage.chatStatus as string) === 'filled') {
    chatStorage.chatStatus = 'ended'
  }

  // Reset every storage with the old branching

  if (chatStorage.chatTimeline?.some((record) => 'key' in record && record.key === 'all_new_or_replacement')) {
    chatStorage.chatTimeline = getInitialTimeline(chatFlow)
  }

  return chatStorage
}

export function useChatStorage<T extends IChatFlow>(
  // TODO: how to fix this type?
  chatFlow: T,
  chatSource: PredefinedChatStorageSource<T>
) {
  const persistChatMutation = useMutation({
    mutationFn: async (newStorage: PredefinedChatStorage<T>) => {
      await chatSource.setStorage(newStorage)
      return newStorage
    },
    onSuccess: (newStorage) => {
      updateChatCache(newStorage)
    }
  })

  const persistChat = React.useCallback(
    (setter: (oldData: PredefinedChatStorage<T>) => PredefinedChatStorage<T>) => {
      // A setter function is needed because storage.data is not guaranteed to be up-to-date,
      // while queryClient.getQueryData(CHAT_STORAGE_QUERY_KEY) is.
      const newStorage = setter(getChatCache<T>()!)

      updateChatCache(newStorage)
      persistChatMutation.mutate(newStorage)
    },
    [persistChatMutation]
  )

  const initialTimeline = useInitialTimeline(chatFlow)

  const initialStorage = React.useRef<PredefinedChatStorage<T>>({
    chatTimeline: initialTimeline,
    chatAnswers: {} as Record<keyof T, any>,
    chatStatus: 'active'
  })

  const chatStorage = useQuery({
    queryKey: CHAT_STORAGE_QUERY_KEY,
    staleTime: chatSource.staleTime,
    queryFn: async () => {
      const result = await chatSource.getStorage()
      if (result) {
        // Recover from incompatible chat flow saved in storage
        if (Object.keys(result.chatAnswers).some((key) => !(key in chatFlow))) {
          return initialStorage.current
        }

        return applyBackwardsCompatibility(result, chatFlow)
      }

      return { ...initialStorage.current, chatStatus: getChatStatus(chatFlow, initialTimeline) }
    },
    initialData: initialStorage.current
  })

  const restartChat = React.useCallback(() => {
    persistChat(() => ({
      chatTimeline: initialTimeline,
      chatAnswers: {} as Record<keyof T, any>,
      chatStatus: 'active'
    }))
  }, [persistChat, initialTimeline])

  const onMessage = React.useCallback(
    (payload: OnMessagePayload<PredefinedChatMessageRecord<T>>) => {
      persistChat((oldStorage) => ({
        chatTimeline: [...oldStorage.chatTimeline, payload.messageRecord],
        chatAnswers: oldStorage.chatAnswers,
        chatStatus: getChatStatus(chatFlow, [...oldStorage.chatTimeline, payload.messageRecord])
      }))
    },
    [chatFlow, persistChat]
  )

  const onServerResponse = React.useCallback(
    (parsedAnswer: any, lastQuestionKey?: keyof T) => {
      if (!lastQuestionKey || !chatStorage.data) {
        Logger.error('lastQuestionKey or chatStorage is undefined', lastQuestionKey, chatStorage.data)
        return
      }

      persistChat((oldStorage) => ({
        chatAnswers: { ...oldStorage.chatAnswers, [lastQuestionKey]: parsedAnswer },
        chatTimeline: oldStorage.chatTimeline,
        chatStatus: 'active'
      }))
    },
    [persistChat, chatStorage]
  )

  const userPreferences = React.useMemo(() => {
    return chatStorage.data?.chatStatus === 'ended' ? chatAnswersToPreference(chatStorage.data!.chatAnswers) : undefined
  }, [chatStorage.data])

  return {
    onMessage,
    chatStorage,
    restartChat,
    onServerResponse,
    userPreferences
  }
}

export function useAutoChatStorage<T extends IChatFlow>(
  // TODO: how to fix this type?
  chatFlow: T = ChatFlow as any,
  sourceSet = PlanChatSourceSet
) {
  const currentUser = useAuthStore((state) => state.currentUser)
  const chatSource = currentUser ? sourceSet.remoteSource : sourceSet.localSource
  return useChatStorage(chatFlow, chatSource)
}

import { css, cx } from '@emotion/css'
import { IonButton } from '@ionic/react'
import React from 'react'
import CloseButton from '../../components/CloseButton/CloseButton'
import TModal, { ModalHeadingStyles } from '../../components/TModal/TModal'
import { trackAmplitudeEvent } from '../../utils/amplitude'
import { ResetMargin } from '../../utils/styles/MarginUtils.styles'
import { Deal, PlanDeal } from './deal.service'

const DescriptionStyles = css`
  margin-bottom: 32px;
`

type DealsRedirectionModalProps = {
  selectedDeal: Deal | PlanDeal | null
  onDidDismiss: () => void
}
export function DealsRedirectionModal(props: DealsRedirectionModalProps) {
  const { onDidDismiss, selectedDeal } = props

  const onDismiss = React.useCallback(() => {
    trackAmplitudeEvent('Deals - navigated to carrier website', { deal: selectedDeal, from: 'warning modal' })
    onDidDismiss()
  }, [onDidDismiss, selectedDeal])

  if (!props.selectedDeal) {
    return null
  }

  return (
    <TModal onClose={props.onDidDismiss} isOpen>
      <section className={ModalHeadingStyles}>
        <div className='close-button'>
          <CloseButton onClose={props.onDidDismiss} />
        </div>
        <h2 className={cx(ResetMargin, 'title')}>Before we redirect you...</h2>
      </section>
      <div className={DescriptionStyles}>
        Be sure to find the corresponding promotion and review the carrier's terms and conditions to apply the deal.
      </div>
      <a target='_blank' rel='noreferrer' href={props.selectedDeal.url}>
        <IonButton onClick={onDismiss} expand='block' size='large' shape='round'>
          Go to carrier site
        </IonButton>
      </a>
    </TModal>
  )
}

import { Redirect, useLocation } from 'react-router'
import { RoutePaths } from '../../paths'
import { useAutoChatStorage } from './hooks/useChatStorage'

export function ChatEntrypoint() {
  const location = useLocation()
  const { chatStorage } = useAutoChatStorage()

  if (chatStorage.data?.chatStatus === 'ended') {
    return <Redirect to={RoutePaths.chatFree + location.search} />
  }

  return <Redirect to={RoutePaths.chatFinder + location.search} />
}

import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import API from '../../../../api'
import useAuthStore from '../../../../store/auth'
import { Logger } from '../../../../utils/Logger'
import { ChatTimelineProps } from '../../components/ChatTimeline'
import { ConversationalResponse, ValidatedResults } from '../../ServerResponse'
import { ToolName } from '../../tools/const/tool.const'
import { MessageType } from '../../types/ChatStep'
import { FreeChat, getDefaultTimeline } from '../../utils/chatApiSource'
import { FreeChatSourceSet } from '../../utils/source-sets'
import { useMutationBuffer } from './useMutationBuffer'

interface ServerChatPayload {
  answer: string
  context: (
    | {
        userAnswer: string
      }
    | { assistantAnswer: string; suggestions?: string[] }
    | {
        toolCall: {
          name: ToolName
          input: Record<string, any>
        }
      }
  )[]
}
export function useSendAnswerToLLM() {
  return useMutation(
    'sendAnswerToLLM',
    async (options: { userAnswer: string; timeline: ChatTimelineProps['timeline'] }) => {
      const serverPayload: ServerChatPayload = {
        answer: options.userAnswer,
        context: options.timeline.map((message) => {
          if (message.type === MessageType.STEP) {
            return {
              assistantAnswer: message.question.map((q) => q.text).join(''),
              suggestions: message.suggestions?.map((s) => s.text)
            }
          }

          if (message.type === MessageType.TOOL) {
            return {
              toolCall: {
                name: message.tool,
                input: message.backendInput
              }
            }
          }

          if (message.from === 'bot') {
            return { assistantAnswer: message.text }
          }

          return { userAnswer: message.text }
        })
      }
      try {
        const response: ConversationalResponse = await API.post('/llm/chat', serverPayload as Record<string, any>)
        return response
      } catch (error) {
        return { resultStatus: ValidatedResults.FAILED, error }
      }
    }
  )
}

export const freeChatQueryKey = ['free-chat']

export function useFreeChat() {
  const user = useAuthStore((state) => state.currentUser)
  const dataSource = user ? FreeChatSourceSet.remoteSource : FreeChatSourceSet.localSource

  const queryClient = useQueryClient()
  function updateCache(timeline: ChatTimelineProps['timeline']) {
    const freeChat: FreeChat = {
      chatTimeline: timeline
    }
    queryClient.setQueryData(freeChatQueryKey, freeChat)
  }

  const mutation = useMutation({
    mutationKey: ['free-chat'],
    mutationFn: async (timeline: ChatTimelineProps['timeline']) => {
      const freeChat: FreeChat = {
        chatTimeline: timeline
      }

      const response = await dataSource.setStorage(freeChat)
      return response
    },
    onMutate: async (freeChat) => {
      await queryClient.cancelQueries('free-chat')
      updateCache(freeChat)
    },
    onError: (error) => {
      Logger.error('cannot persist chat', error)
    },
    onSettled: (result) => {
      if (result) {
        updateCache(result.chatTimeline)
      }
    }
  })

  const getState = React.useCallback(() => {
    return queryClient.getQueryData<FreeChat>(freeChatQueryKey)?.chatTimeline || []
  }, [queryClient])

  const { pushToBuffer } = useMutationBuffer(getState, mutation)

  const query = useQuery({
    queryKey: freeChatQueryKey,
    keepPreviousData: true,
    staleTime: 60 * 1000 * 3,
    queryFn: async () => {
      const response = await dataSource.getStorage()
      return response || ({ chatTimeline: getDefaultTimeline() } as FreeChat)
    }
  })

  return {
    query,
    mutate: pushToBuffer
  }
}

import { Logger } from '../Logger'

function getScrollbarWidth() {
  return window.innerWidth - document.documentElement.offsetWidth
}

export function setScrollbarWidthCSSVariable() {
  try {
    document.documentElement.style.setProperty('--scrollbar-width', getScrollbarWidth() + 'px')
  } catch (e) {
    Logger.error('Failed to set scrollbar width CSS variable', e)
  }
}

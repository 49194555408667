import { useQuery } from 'react-query'
import { BackendSyncService } from '../../../services/backend-sync/backend-sync.service'
import { ProfileService } from '../../../services/backend-sync/profile.service'
import { State, useGeneralStore } from '../../../store'
import { applyGeneralStoreToEditStore } from '../../../store/editStoreManagement'
import { BackendUser } from '../../../types/BackendUser'
import { Logger } from '../../../utils/Logger'
import { PlanNotFound } from '../../current-plan/PlanNotFound'
import { updateChatCache } from '../../llm/hooks/useChatStorage'

export const stateToBackendUser = (state: State): BackendUser => {
  const planId = state.currentPlanId === PlanNotFound.planId ? null : state.currentPlanId

  return {
    first_name: '',
    last_name: '',
    current_plan: planId || null,
    current_device: 'device',
    // TODO: backend only accepts number, but returns string
    plan_budget: Number(state.targetBudget) as any,
    plan_preferences: {
      carrierId: state.userCarrierId,
      useUnlimitedDataPlan: state.useUnlimitedDataPlan,
      phoneLinesNeeded: state.phoneLinesNeeded,
      noPlanSelected: state.currentPlanId === PlanNotFound.planId,
      reasonsForSwitch: state.reasonsForSwitch
    },
    locations: [],
    favourite_plan_ids: state.favoritePlanIds,
    searched_plans: state.searchedPlansHistory ?? [],
    searched_addresses: state.searchedAddressHistory ?? []
  }
}

async function promoteLocalChatStateToBackend(backendUser: BackendUser | undefined, syncService: BackendSyncService) {
  if (!backendUser) {
    return
  }

  if (!backendUser.chat_state) {
    await syncService.chatSourceService.uploadFromLocalStorageToApi()
  }

  await syncService.chatSourceService.removeLocalData()
}

export const PROFILE_QUERY_KEY = ['profile/user']
export function useBackendUser(syncService: BackendSyncService) {
  return useQuery(PROFILE_QUERY_KEY, {
    queryFn: async () => {
      try {
        const profileService = new ProfileService(syncService.api)
        const userPromise = profileService.getBackendUser()
        const addressesPromise = profileService.getAddresses()

        const [user, newAddresses] = await Promise.all([userPromise, addressesPromise])

        if (user.chat_state) {
          updateChatCache(user.chat_state)
        }

        const addresses = await syncService.backwardsCompatibleAddresses(user.locations ?? [], newAddresses ?? [])

        if (user) {
          useGeneralStore.setState(BackendSyncService.userToStore(user, addresses))
          applyGeneralStoreToEditStore()
        }

        applyGeneralStoreToEditStore()
        promoteLocalChatStateToBackend(user, syncService)
        return user
      } catch (e) {
        Logger.debug('user not signed in', e)
      }
    },
    staleTime: Infinity
  })
}

import { css } from '@emotion/css'

const ExpirationTextStyles = css`
  color: var(--ion-color-medium);
  font-size: 14px;

  padding-top: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
`
const formatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
})

function getFormattedDateString(dateStr: string): string | undefined {
  const expirationDate = dateStr ? new Date(dateStr) : undefined
  return expirationDate ? formatter.format(expirationDate) : undefined
}

function DealExpirationText(props: { dealExpiration: string }) {
  const expirationDate = getFormattedDateString(props.dealExpiration)

  return expirationDate ? (
    <div className={ExpirationTextStyles}>
      <img src='/assets/icons/ic_baseline-access-time.svg' alt='clock' />
      Expires {expirationDate}
    </div>
  ) : null
}

export default DealExpirationText

import { css } from '@emotion/css'

export const PlanResultCardStyles = css`
  .card-content-md {
    font-size: 16px;
    line-height: 24px;
  }
  .card-content-ios {
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
  }

  margin-left: 0;
  margin-right: 0;
  border: 2px solid #000;
  border-radius: 10px;
  max-width: 1300px;

  .plan-result-card-content {
    height: 100%;
  }

  .plan-result-card-grid {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .favorite-button-row {
    justify-content: space-between;
    margin-top: auto;
  }

  ion-col {
    text-align: left;
    &.extra-margin {
      margin-bottom: 16px;
    }
    margin-bottom: 8px;
  }

  .best-plans-logo {
    max-height: 24px;
    &::part(image) {
      width: initial;
      max-height: inherit;
    }
    margin-bottom: 8px;
  }

  .plan-name {
    margin-top: 4px;
  }

  .network-performance {
    color: #878787;
  }

  .top-bar {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    height: 8px;
    padding-right: 8px;
    background-color: var(--goji-color-light-blue);
    &.saved {
      height: 34px;
    }
    ion-icon {
      font-size: 22px;
    }
  }

  .plan-price,
  .plan-price + h6 {
    text-align: right;
    margin: 0;
    color: var(--ion-color-dark);
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .plan-price {
    font-weight: 700 !important;
    font-size: 32px;
    color: var(--ion-color-dark);
    word-wrap: normal;
  }
  .short-plan-name {
    font-weight: 500;
    max-width: 250px;
  }

  .divider {
    width: 100%;
    height: 8px;
    border-bottom: 1px solid var(--ion-color-light);
    content: '';
  }

  @media screen and (max-width: 430px) {
    .plan-price {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 310px) {
    .plan-price {
      font-size: 18px;
    }
  }

  @media screen and (min-width: 768px) {
    cursor: pointer;
  }
`
